import * as SentryTracer from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRD';
const sentryKey = process.env.REACT_APP_SENTRY;

export const SentryInitializer = () => {
  if (isProd && sentryKey) {
    SentryTracer.init({
      dsn: sentryKey,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

export default SentryTracer;
