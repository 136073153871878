import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Text } from '@zion-tech/ds-web';
import { zion } from '@zion-tech/ds-tokens';
import useAuthContext from '@shared/auth/Auth.context';
import { getGroups, setGroupSelected } from '@models/Group/Group.state';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import { getUser } from '@models/User/User.state';
import { GroupEmailSendService } from '@models/Group/Group.service';
import StatusMessage from '@components/StatusMessage';
import { mountWhatsAppLink } from '../utils';

const GroupWhatsAppPage = ({ title }) => {
  const { t: translate } = useTranslation();
  const dispach = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const groups = useSelector(getGroups);
  const linkWhatsAppButton = useRef<HTMLAnchorElement>(null);
  const authContext = useAuthContext();

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    redirectIfNoSelectedOrAuthed();
    trackEvent({
      type: 'info',
      name: 'ZionOnline:Links:Contato',
      value: {
        userId: user?.data?.id,
        userName: user?.data?.name,
        groupId: groups?.selected?.id,
        groupName: groups?.selected?.name,
      },
    });
    sendToWhatsApp();
  }, []);

  const sendToWhatsApp = () => {
    setTimeout(async () => {
      linkWhatsAppButton?.current?.click();
      await GroupEmailSendService(groups?.selected?.id);
    }, 4000);
  };

  const redirectIfNoSelectedOrAuthed = () => {
    if (!groups?.selected || groups?.selected === null || !authContext.isUserAuthenticated()) {
      handleReturnToSearchGroups();
    }
  };

  const handleReturnToSearchGroups = () => {
    dispach(setGroupSelected(undefined));
    navigate(`/links`, { replace: false });
  };

  return groups?.selected ? (
    <StatusMessage
      key={3}
      illustration='whatsapp'
      title={translate(
        'Vamos te redirecionar para o WhatsApp do líder do LINK escolhido por você.',
      )}
      description={translate('Assim que ele(a) puder, responderá sua mensagem.')}
      style={{ display: 'flex', height: '90' }}
      buttonLabel={translate('Voltar para a busca de LINKs')}
      onButtonClick={() => handleReturnToSearchGroups()}>
      <Box
        align='center'
        gap='18px'
        style={{
          width: 400,
          background: zion.global.colors['background-contrast'].dark,
          borderRadius: 10,
          padding: 16,
          margin: '18px 0 0px 0',
        }}>
        <Text size='small'>
          {translate(
            'Caso não seja redirecionado, desabilite qualquer tipo de bloqueio de pop-up e tente novamente,',
          )}{' '}
          <a
            style={{ color: '#3daeab' }}
            ref={linkWhatsAppButton}
            href={mountWhatsAppLink(user, groups?.selected)}
            target='_blank'
            rel='noreferrer'>
            {translate('clicando aqui.')}
          </a>
        </Text>
      </Box>
    </StatusMessage>
  ) : null;
};

export default GroupWhatsAppPage;
