import { IGroup, IGroupFilters, IMarker, WeekdayEnum } from '@models/Group/Group.model';
import { GlobalDDIEnum } from '@shared/global';
import { capitalizeWord, getName } from '@shared/naming';

export const mountWhatsAppLink = (user, group: IGroup) => {
  const cellphoneDDI = GlobalDDIEnum[group?.location?.country] || GlobalDDIEnum.DEFAULT;
  const URL = `https://wa.me/${cellphoneDDI}${group?.contact?.phone}`;
  const MESSAGE = `?text=Olá, tudo bem? Me chamo ${capitalizeWord(
    getName(user?.data?.name).first,
  )} localizei seu LINK através da plataforma online da Zion Church e gostaria de participar do seu grupo.`;
  return `${URL}${MESSAGE}`;
};

export function mountFilter(value: any): IGroupFilters {
  return {
    filters: {
      distance: 10000,
      ...value,
      dayId: value?.day && WeekdayEnum[value?.day],
    },
  };
}

export const mountAddressFilter = ({
  location,
  lat,
  lng,
}: {
  location: any;
  lat: number;
  lng: number;
}) => {
  let address = null;
  // Using Google Places API schema
  if (location?.terms) {
    if (location?.terms.length > 4) {
      address = {
        street: location?.terms[0]?.value,
        district: location?.terms[1]?.value,
        cityName: location?.terms[2]?.value,
        stateName: location?.terms[3]?.value,
        countryName: location?.terms[4]?.value,
        lat,
        lng,
      };
    } else {
      address = {
        street: location?.terms[0]?.value,
        district: location?.terms[0]?.value,
        cityName: location?.terms[1]?.value,
        stateName: location?.terms[2]?.value,
        countryName: location?.terms[3]?.value,
        lat,
        lng,
      };
    }
    // Using Geolocation Schema
  } else {
    address = {
      street: location?.route,
      district: location?.political,
      cityName: location?.administrative_area_level_1,
      stateName: location?.administrative_area_level_2,
      countryName: location?.country,
      lat,
      lng,
    };
  }
  return address;
};

export function mountGroupList(groupList: IGroup[]): Array<IGroup> {
  const sorted = groupList.sort((a, b) => {
    if (a.statusName === 'LOTADO' && b.statusName !== 'LOTADO') return 1;
    if (a.statusName !== 'LOTADO' && b.statusName === 'LOTADO') return -1;
    return 0;
  });
  return sorted.filter(mark => mark?.location?.lat || mark?.location?.lng);
}

export function mountMarkers({
  mark,
  leaderLabel,
  dayLabel,
  timeLabel,
  buttonLabel,
}: {
  mark: IGroup;
  leaderLabel: string;
  dayLabel: string;
  timeLabel: string;
  buttonLabel: string;
}): IMarker {
  const tooltipContent = `
    <div style="padding: 8px 0px; color: #333">
      ${
        mark?.statusName === 'LOTADO'
          ? `<div style="
          width: fit-content;
          padding: 4px 12px 4px 12px;
          border-radius: 8px;
          background: #D68585;
          font-weight: 600;
          margin: auto;
        ">
          LOTADO
        </div>`
          : ''
      }
      <p style="text-transform: capitalize;">
        <strong>${leaderLabel}:</strong> 
        ${
          getName(Object.keys(mark?.managers).length !== 0 ? mark?.managers[0].name : 'Zion').first
        } 
        ${getName(Object.keys(mark?.managers).length !== 0 ? mark?.managers[0].name : 'LINK').last}
      </p>
      <p><strong>${dayLabel}:</strong> 
      ${WeekdayEnum[mark?.operationDays?.[0]?.dayId]}
      </p>
      <p>
        <strong>${timeLabel}:</strong> 
        ${mark?.operationDays?.[0].startAt} a 
        ${mark?.operationDays?.[0].endAt}
      </p>
    </div>
  `;

  return {
    ...mark,
    buttonLabel: buttonLabel,
    subtitle: mark?.categoryName,
    title: mark?.name,
    disabled: mark?.statusName === 'LOTADO',
    content: tooltipContent,
    lat: mark?.location?.lat,
    lng: mark?.location?.lng,
  };
}
