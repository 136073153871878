import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Container = styled(ToastContainer)`
  width: 380px;

  .Toastify__toast {
    background: #161d28;
    border-radius: 4px;
    padding: 16px;
    box-shadow: rgb(0 0 0 / 16%) 0px 4px 8px 0px;

    font-family: 'Montserrat', Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
  }

  .Toastify__toast-body {
    gap: 16px;
  }

  .Toastify__close-button--light {
    color: #fff;
  }
`;
