import Skeleton from '@components/Skeleton';
import StatusMessage from '@components/StatusMessage';
import { IGroup, WeekdayEnum } from '@models/Group/Group.model';
import { getName } from '@shared/naming';
import { Box, Col, Row, Text } from '@zion-tech/ds-web';
import { Calendar, Clock, User } from 'grommet-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardGroup from './CardGroup';

export interface IGroupsListProps {
  locations: IGroup[];
  title?: string;
  onButtonClick?: any;
}

const GroupsList: React.FC<IGroupsListProps> = ({ locations, onButtonClick, title }) => {
  const { t: translate } = useTranslation();
  const skeletons = [1, 2, 3, 4, 5, 6];

  const refreshWebApp = () => {
    window.location.reload();
  };

  const MessageAlert = (
    <StatusMessage
      key={1}
      illustration='empty'
      style={{ display: 'flex', height: '90', marginBottom: 48 }}
      boxStyle={{ width: '80%' }}
      title={translate('Infelizmente não temos link presencial nessa região')}
      titleWeight='600'
      titleSize='20px'
      buttonLabel={translate('Voltar para a busca de LINKs')}
      onButtonClick={() => refreshWebApp()}
    />
  );

  const Cards =
    locations && locations?.length > 0
      ? locations?.map(group => (
          <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={4} key={group.id}>
            <CardGroup
              group={group}
              status={group.statusName}
              title={group.name?.toLocaleLowerCase()}
              subtitle={group.categoryName}
              onButtonClick={onButtonClick}>
              <Box flex direction='column' gap='4px'>
                {group?.managers && (
                  <Text
                    color='white'
                    weight={500}
                    size='12px'
                    style={{ textTransform: 'capitalize' }}>
                    <User size='small' />
                    &nbsp;
                    {
                      getName(
                        Object.keys(group.managers).length !== 0 ? group.managers[0].name : 'Zion',
                      ).first
                    }
                    &nbsp;
                    {
                      getName(
                        Object.keys(group.managers).length !== 0 ? group.managers[0].name : 'LINK',
                      ).last
                    }
                  </Text>
                )}
                <Text
                  color='white'
                  weight={500}
                  size='12px'
                  style={{ textTransform: 'capitalize' }}>
                  <Calendar size='small' />
                  &nbsp;
                  {translate(WeekdayEnum[group?.operationDays?.[0]?.dayId])}
                </Text>
                <Text color='white' weight={500} size='12px'>
                  <Clock size='small' />
                  &nbsp;
                  {group.operationDays?.[0].startAt} {translate('a')}{' '}
                  {group.operationDays?.[0].endAt}
                </Text>
              </Box>
            </CardGroup>
          </Col>
        ))
      : '';

  return (
    <Box style={{ maxWidth: 710, minHeight: 'auto' }} pad={{ horizontal: 'small' }}>
      <Row>{locations?.length < 1 ? <Col>{MessageAlert}</Col> : ''}</Row>
      <Row>
        {locations?.length > 0 ? (
          <Col>
            <Box margin={{ bottom: '32px' }} style={{ gap: 12 }}>
              <Box flex direction='row' style={{ gap: 8 }}>
                <Box
                  background='white'
                  pad={{ horizontal: 'xsmall' }}
                  style={{ display: 'table', maxHeight: '21px' }}
                  round>
                  <Text size='small' weight='bold'>
                    {locations?.length}
                  </Text>
                </Box>
                <Text size='medium' weight='bold' style={{}}>
                  {title || translate('Links Disponíveis para você participar')}:
                </Text>
              </Box>
            </Box>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Row>
        {locations
          ? Cards
          : skeletons.map(item => (
              <Col sm={12} md={12} lg={6} xl={6} xxl={4} key={item}>
                <Skeleton loading radius='12px' width='100%' height='270px' />
              </Col>
            ))}
      </Row>
    </Box>
  );
};

export default GroupsList;
