import React, { useState } from 'react';
import { Menu } from '@zion-tech/ds-web';
import { useTranslation } from 'react-i18next';

export const LanguageMenu = () => {
  const { t: translate, i18n } = useTranslation();
  const items = [
    {
      label: translate('Português'),
      value: 'pt-BR',
      flag: '🇧🇷',
      onClick: () => handleChangeLang('pt-BR'),
    },
    {
      label: translate('Inglês'),
      value: 'en-US',
      flag: '🇺🇲',
      onClick: () => handleChangeLang('en-US'),
    },
    {
      label: translate('Espanhol'),
      value: 'es',
      flag: '🇪🇸',
      onClick: () => handleChangeLang('es'),
    },
  ];

  const refreshWebApp = () => {
    window.location.reload();
  };

  const getLanguageItem = lang => {
    return items.find(i => i.value === lang);
  };

  const handleChangeLang = lang => {
    const langItem = getLanguageItem(lang);
    if (langItem) {
      i18n.changeLanguage(langItem.value);
      setCurrLang(langItem);
      refreshWebApp();
    }
  };

  const [currLang, setCurrLang] = useState(getLanguageItem(i18n.language) || items[0]);

  return (
    <Menu label={currLang.flag} items={items} size='small' style={{ padding: 12 }} gap='8px' />
  );
};
