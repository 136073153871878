import { IGroup, IGroupType } from '@models/Group/Group.model';
import { GroupSearchService, GroupTypesService } from '@models/Group/Group.service';
import {
  getGroups,
  setGroupList,
  setGroupSelected,
  setGroupTypes,
} from '@models/Group/Group.state';
import { FiltersFormContent } from '@pages/Groups/Groups.style';
import FiltersForm from '@pages/Groups/components/FiltersForm';
import GroupsList from '@pages/Groups/components/GroupsList';
import { mountFilter, mountGroupList } from '@pages/Groups/utils';
import { IResponse, IResponseEnum } from '@shared/api/Api.model';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GroupSearchPage = ({ title }) => {
  const dispach = useDispatch();
  const navigate = useNavigate();
  const groups = useSelector(getGroups);

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getGroupTypesAndRegions();
  }, []);

  const getGroupTypesAndRegions = async () => {
    if (!groups?.types || groups?.types.length === 0) {
      const typesResponse: IResponse = await GroupTypesService();
      if (typesResponse.status.type === IResponseEnum.success) {
        const types = typesResponse?.data?.categories?.filter((type: IGroupType) =>
          type.name.includes('LINK'),
        );
        dispach(setGroupTypes(types));
      }
    }
  };

  const handleFilter = async value => {
    dispach(setGroupList(undefined));
    const valueChecked = checkAddress(value);
    const response = await GroupSearchService(mountFilter(valueChecked));
    if (
      response &&
      response?.status?.type === IResponseEnum.success &&
      response?.homeGroups?.length > 0
    ) {
      const list = mountGroupList(response.homeGroups);
      dispach(setGroupList(list));
    } else {
      dispach(setGroupList([]));
    }
    trackEvent({
      type: 'info',
      name: 'ZionOnline:Links:Buscar',
      value: {
        address: value?.address || '',
        day: value?.day || '',
        category: value?.category?.name || '',
      },
    });
  };

  const checkAddress = value => {
    let valuesToFind = value;
    if (!value.address) {
      valuesToFind = {
        ...value,
        address: 'Rua Liberato Carvalho Leite, 86 - Vila Suzana, São Paulo - SP, Brazil',
        location: {
          street: 'Rua Liberato Carvalho Leite',
          district: '86, Vila Suzana',
          cityName: 'São Paulo',
          stateName: 'São Paulo',
          countryName: 'Brazil',
          lat: -23.61346,
          lng: -46.73919,
        },
      };
    }
    return valuesToFind;
  };

  const handleSelectGroup = (group: IGroup) => {
    dispach(setGroupSelected(group));
    navigate(`details`, { replace: false });
    trackEvent({
      type: 'info',
      name: 'ZionOnline:Links:QueroParticipar',
      value: {
        group: {
          id: group?.id,
          categoryName: group?.categoryName,
          name: group?.name,
          street: group?.location?.street,
          district: group?.location?.district,
          city: group?.location?.city,
          state: group?.location?.state,
          country: group?.location?.country,
          status: group?.statusName,
          operationDays: group?.operationDays,
          managers: group?.managers,
        },
      },
    });
  };

  return (
    <>
      <FiltersFormContent>
        <FiltersForm groupTypes={groups?.types} submitForm={value => handleFilter(value)} />
      </FiltersFormContent>
      <GroupsList locations={groups?.list} onButtonClick={group => handleSelectGroup(group)} />
    </>
  );
};

export default GroupSearchPage;
