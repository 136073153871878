import styled from 'styled-components';

export const StyledContainer = styled.main`
  width: 100%;
  height: ${props => (props.fullheight ? `100vh !important` : `100% !important`)};
  scroll-behavior: smooth;
  ${props => (props.fullheight ? `overflow-x: hidden` : ``)};
  ${props => (props.fixedHeader ? 'padding-top: 100px' : '')};
  ${props =>
    props.bgGradient
      ? `
        background-image: radial-gradient(
        circle farthest-corner at 10% 20%,
        rgba(0, 152, 155, 1) 0.1%,
        rgba(0, 94, 120, 1) 94.2%
      );`
      : ''};
`;
