import { IUser } from '@models/User/User.model';
import * as LocalStorage from 'local-storage';
import { getFromCookie, removeFromCookie, writeOnCookie } from './cookies';

export interface Payload {
  apiKey: string;
  role: string;
  institutionId: string;
  institutionSlug: string;
  proverInstitutionId: number;
  correlationId: string;
  userName: string;
  userId: string;
  proverUserId: number;
  proverUserApiKey: string;
  iat: number;
  exp: number;
  iss: string;
  sub: string;
}

const userToken = 'ZION-USR-TKN';
const LOCAL_STORAGE_USR = 'ZION-USR';

const writeOnLocalStorage = (name, value) => {
  return LocalStorage.set<any>(name, value);
};

// export const getFromLocalStorage = name => {
//   return LocalStorage.get<any>(name);
// };

export const removeFromLocalStorage = name => {
  return LocalStorage.remove(name);
};

export const deleteAllFromLocalStorage = () => {
  return LocalStorage.clear();
};

export function getToken(): string {
  return getFromCookie(userToken);
}

export function setToken(token: string, userInfo: string) {
  writeOnCookie(userToken, token);
  if (userInfo) writeOnLocalStorage(LOCAL_STORAGE_USR, userInfo);
}

export function clearAuthentication() {
  removeFromCookie(userToken);
  removeFromLocalStorage(LOCAL_STORAGE_USR);
}

export function getUserInfo(): IUser | any {
  return LocalStorage.get<any>(LOCAL_STORAGE_USR);
}

function isApplicationAuthenticated() {
  const payload = getPayload();

  if (!payload) {
    return false;
  }

  return true;
}

function isUserAuthenticated() {
  const payload = getPayload();

  if (!payload) {
    return false;
  }

  const now = Date.now().valueOf() / 1000;

  if (payload.exp < now) {
    return false;
  }

  if (!payload.userId) {
    return false;
  }

  return true;
}

function getPayload(): Payload {
  const token = getFromCookie(userToken);

  if (!token) {
    return null;
  }

  return JSON.parse(atob(token.split('.')[1]));
}

function isTokenExpired() {
  const payload = getPayload();

  if (!payload) {
    return true;
  }

  const now = Date.now().valueOf() / 1000;

  if (payload.exp < now) {
    return true;
  }

  return false;
}

export default function useAuthContext() {
  return {
    getToken,
    setToken,
    removeToken: clearAuthentication,
    isTokenExpired,
    getUserInfo,
    isApplicationAuthenticated,
    isUserAuthenticated,
    deleteAllFromLocalStorage,
  };
}
