import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormControl, TextInput, Text } from '@zion-tech/ds-web';
import { RestorePasswordService } from '@shared/auth/Auth.service';
import { Subtitle, FormContainer } from './RestorePasswordForm.style';

const RestorePasswordForm = ({
  showTitle = false,
  showSubtitle = false,
  showCloseButton = false,
  onSuccess = undefined,
  onError = undefined,
  onCloseButtonClick = undefined,
}) => {
  const { t: translate } = useTranslation();

  const handleRestorePassword = async value => {
    const response = await RestorePasswordService(value);
    if (response.status.type === 'success') {
      onSuccess(response.status);
    } else {
      onError(response.status);
    }
  };
  return (
    <FormContainer flex direction='column' align='center'>
      {showTitle && (
        <Text size='large' style={{ paddingBottom: 32, textAlign: 'left' }}>
          {translate('Restaurar senha')}
        </Text>
      )}
      {showSubtitle && (
        <Subtitle style={{ display: 'block' }}>
          {translate('Você receberá um e-mail com informações para cadastrar uma nova senha.')}
        </Subtitle>
      )}
      <Form onSubmit={({ value }) => handleRestorePassword(value)} style={{ width: 310 }}>
        <FormControl
          label={translate('E-mail')}
          message={translate('O mesmo usuário utilizado no App da Zion')}
          style={{ marginBottom: 16 }}
          htmlFor='email'
          required>
          <TextInput required type='email' name='email' id='email' style={{ width: '100%' }} />
        </FormControl>
        <Button
          rounded
          type='submit'
          variant='primary'
          label={translate('Recuperar minha senha')}
          style={{ width: '100%', marginTop: 36, justifyContent: 'center' }}
        />
      </Form>
      {showCloseButton && (
        <Button
          rounded
          variant='ghost'
          label={translate('Voltar para o Login')}
          style={{ width: '100%', marginTop: 16, justifyContent: 'center' }}
          onClick={onCloseButtonClick}
        />
      )}
    </FormContainer>
  );
};

export default RestorePasswordForm;
