import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormView, Hide } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import { Button, Form, FormControl, Text, TextInput } from '@zion-tech/ds-web';
import { updateUser } from '@models/User/User.state';
import { LoginService } from '@shared/auth/Auth.service';
import { IUser } from '@models/User/User.model';
import { IResponseEnum } from '@shared/api/Api.model';
import { FormContainer, PasswordField, PasswordInput } from './LoginForm.style';
import useAuthContext from '../Auth.context';

const LoginForm = ({
  showTitle = false,
  shouldRedirect = false,
  redirectTo = '/',
  onSuccess = undefined,
  onError = undefined,
  onClickRestorePassword = undefined,
  onClickSignUp = undefined,
}) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispach = useDispatch();

  const [disabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputPasswordFocused, setinputPasswordFocused] = useState(false);
  interface newState {
    from: any;
  }

  const state = location.state as newState;
  const authContext = useAuthContext();

  // useEffect(
  //   useCallback(() => {
  //   }, []),
  // );

  const handleLogin = async value => {
    setLoading(true);
    authContext.removeToken();
    const response = await LoginService(value);
    if (response.status.type === IResponseEnum.success) {
      const userFromStorage: IUser = authContext.getUserInfo();
      dispach(updateUser(userFromStorage));
      onSuccess?.(response.status);
      setLoading(false);
      const redirect = state.from.pathname || redirectTo;
      if (shouldRedirect) navigate(redirect);
    } else {
      onError(response.status);
      setLoading(false);
    }
  };

  return (
    <FormContainer flex direction='column' align='center'>
      {showTitle && (
        <Text size='large' style={{ paddingBottom: 32, textAlign: 'left' }}>
          {translate('Acessar minha conta')}
        </Text>
      )}
      <Form onSubmit={({ value }) => handleLogin(value)} style={{ width: 310 }}>
        <FormControl
          label={translate('E-mail')}
          message={translate('O mesmo usuário utilizado no App da Zion')}
          style={{ marginBottom: 16 }}
          htmlFor='username'>
          <TextInput
            required
            type='text'
            name='username'
            id='username'
            readOnly={loading}
            style={{ width: '100%' }}
          />
        </FormControl>
        <FormControl
          label={translate('Senha')}
          message={translate('A mesma senha utilizada no App da Zion')}
          style={{ marginBottom: 36 }}>
          <PasswordField
            htmlFor='password'
            focused={inputPasswordFocused}
            direction='row'
            align='center'>
            <PasswordInput
              required
              plain
              name='password'
              id='password'
              type={showPassword ? 'text' : 'password'}
              readOnly={loading}
              onFocus={() => setinputPasswordFocused(true)}
              onBlur={() => setinputPasswordFocused(false)}
              style={{ width: '100%' }}
            />
            {showPassword ? (
              <Hide onClick={() => setShowPassword(!showPassword)} style={{ marginRight: 8 }} />
            ) : (
              <FormView onClick={() => setShowPassword(!showPassword)} style={{ marginRight: 8 }} />
            )}
          </PasswordField>
        </FormControl>
        <Button
          rounded
          loading={loading}
          disabled={disabled}
          type='submit'
          variant='primary'
          label={translate('Login')}
          style={{ width: '100%', justifyContent: 'center' }}
        />
      </Form>
      <Button
        rounded
        disabled={loading}
        variant='secondary'
        label={translate('Cadastrar-se')}
        style={{ width: '100%', marginTop: 16, justifyContent: 'center' }}
        onClick={onClickSignUp}
      />
      <Button
        rounded
        disabled={loading}
        variant='ghost'
        label={translate('Esqueci minha senha')}
        style={{ width: '100%', marginTop: 16, justifyContent: 'center' }}
        onClick={onClickRestorePassword}
      />
    </FormContainer>
  );
};

export default LoginForm;
