import { createGlobalStyle } from 'styled-components';
import { zion } from '@zion-tech/ds-tokens';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Montserrat', Helvetica, sans-serif;
  }

  html {
    font-size: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${zion.global.colors.background[zion.defaultMode]};
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    font-size: 16px;
  }

  /** Scroll Bar **/
  ::-webkit-scrollbar {
    width: 1px;
  }
  ::-webkit-scrollbar-track {
    background: ${zion.global.colors.background[zion.defaultMode]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${zion.global.colors.background[zion.defaultMode]};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${zion.global.colors.background[zion.defaultMode]};
    cursor: grab;
  }
`;

export default GlobalStyle;
