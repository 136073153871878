import {
  ITrackIdentify,
  ITrackEvent,
  ITrackPageView,
} from '@shared/tracking/segment/Segment.model';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRD';
const hasWindow = typeof window !== 'undefined';

// declare const analytics;
let analytics;

export const trackIdentify = ({ userId, name, email, phone }: ITrackIdentify) => {
  if (isProd && hasWindow && analytics) {
    analytics.identify(userId, {
      name: name,
      email: email,
      phone: phone,
    });
  }
};

export const trackEvent = ({ type = 'info', name = 'EVENT_NAME', value = {} }: ITrackEvent) => {
  if (isProd && hasWindow && analytics) {
    analytics.track(type, { name: name, ...value });
  }
};

export const trackPageView = ({
  name = '',
  title = 'Zion Church',
  path = '',
  state = undefined,
}: ITrackPageView) => {
  if (isProd && hasWindow && analytics) {
    analytics.page(name, {
      title: title,
      path: path,
      state: state,
    });
  }
};

export const trackLink = ({ element, name, value }) => {
  if (isProd && hasWindow && analytics) {
    analytics.trackLink(element, name, value);
  }
};

export const trackForm = ({ element, name, value }) => {
  if (isProd && hasWindow && analytics) {
    analytics.trackForm(element, name, value);
  }
};
