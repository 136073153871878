import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container, Box } from '@zion-tech/ds-web';
import { useNavigate } from 'react-router-dom';
import { Header, Page, StatusMessage } from '@components/index';

const NotFound404 = ({ title }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const handleReturnToHome = () => {
    navigate('/', { replace: false });
  };

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <Header />
      <Page fluid>
        <Container style={{ marginTop: 48 }}>
          <Row>
            <Col>
              <Box margin={{ bottom: '32px' }}>
                <StatusMessage
                  key={1}
                  illustration='empty'
                  title={translate('Página não encontrada')}
                  description={translate(
                    'A página que você tentou acessar está indisponivel ou não existe.',
                  )}
                  buttonLabel={translate('Retornar à página inicial')}
                  onButtonClick={() => handleReturnToHome()}
                  style={{ display: 'flex', height: '90', marginTop: 24 }}
                />
              </Box>
            </Col>
          </Row>
        </Container>
      </Page>
    </>
  );
};

export default NotFound404;
