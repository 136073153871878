import React, { useState, useRef, useCallback, useEffect } from 'react';
import { TbPlayerPause, TbPlayerPlay } from 'react-icons/tb';
import { BsSpotify } from 'react-icons/bs';
import {
  Container,
  Title,
  AudioControls,
  PlayPauseButton,
  ProgressAudio,
  Informations,
} from './styles';

interface AudioPlayerProps {
  audioSrc: string;
  title: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioSrc, title }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [trackProgress, setTrackProgress] = useState(0);

  const audioRef = useRef<HTMLAudioElement>(new Audio(audioSrc));
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
  const { duration } = audioRef.current;

  const startTime = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setTrackProgress(audioRef.current.currentTime);
    }, 1000);
  }, []);

  const onScrub = useCallback((value: number) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  }, []);

  const onScrubEnd = useCallback(() => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTime();
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTime();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Container>
      <AudioControls>
        <PlayPauseButton
          type='button'
          aria-label={isPlaying ? 'Pause' : 'Play'}
          onClick={() => setIsPlaying(!isPlaying)}>
          {isPlaying ? <TbPlayerPause /> : <TbPlayerPlay />}
        </PlayPauseButton>
        <Informations>
          <Title>
            <BsSpotify /> {title}
          </Title>
          <ProgressAudio
            type='range'
            value={trackProgress}
            step='1'
            min='0'
            max={String(duration)}
            onChange={e => onScrub(Number(e.target.value))}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
          />
        </Informations>
      </AudioControls>
    </Container>
  );
};

export default AudioPlayer;
