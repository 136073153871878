import React, { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormControl, TextInput } from '@zion-tech/ds-web';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import { useLocation } from 'react-router-dom';
import { removeBar, firstLetterUpper } from '@shared/naming';
import { getDDIList } from '@shared/services/ddi-list.service';
import Select from '@components/Select/Select';

const StepOne = ({ nextStep, handleFormData, values }) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const [status, setStatus] = useState({
    type: '',
    message: '',
  });

  const ddiList = getDDIList().map(ddi => ({
    label: `${ddi.pais} (+${ddi.ddi})`,
    value: ddi.ddi,
  }));

  const submitFormData = async e => {
    e.preventDefault();
    if (!(await validate())) return;
    const { value } = e;
    trackEvent({
      type: 'info',
      name: `ZionOnline:${firstLetterUpper(removeBar(location.pathname))}:Cadastro:Passo1`,
      value: {
        nomeCompleto: value?.name,
        celular: value?.cellphone,
      },
    });
    nextStep();
  };

  const validate = async () => {
    const schema = yup.object().shape({
      cellphone: yup.string().required('O campo é obrigatório'),
      ddiCellphone: yup.string().required('O campo é obrigatório'),
      name: yup
        .string()
        .matches(/(\w+)(\s)(\w+)/, { message: 'Por favor informe seu nome completo' }),
    });
    try {
      await schema.validate(values);
      return true;
    } catch (err) {
      setStatus({
        type: err.path,
        message: err.errors,
      });
      return false;
    }
  };

  return (
    <Form onSubmit={submitFormData} style={{ width: 310 }}>
      <FormControl
        label={translate('Nome completo')}
        style={{ marginBottom: 16 }}
        htmlFor='name'
        error={status.type === 'name'}
        message={status.type === 'name' ? translate(status.message) : null}>
        <TextInput
          type='text'
          name='name'
          id='name'
          defaultValue={values.name}
          onChange={handleFormData('name')}
          style={{ width: '100%' }}
        />
      </FormControl>
      <FormControl
        label={translate('Codigo País')}
        style={{ marginBottom: 16 }}
        htmlFor='ddiCellphone'
        error={status.type === 'ddiCellphone'}
        message={status.type === 'ddiCellphone' ? translate(status.message) : null}>
        <Select
          search
          name='ddiCellphone'
          id='ddiCellphone'
          onSelectOption={handleFormData('ddiCellphone')}
          options={ddiList}
        />
      </FormControl>
      <FormControl
        label={translate('Celular')}
        style={{ marginBottom: 16 }}
        htmlFor='cellphone'
        error={status.type === 'cellphone'}
        message={status.type === 'cellphone' ? translate(status.message) : null}>
        <TextInput
          type='phone'
          name='cellphone'
          id='cellphone'
          maxLength={16}
          value={values.cellphone}
          onChange={handleFormData('cellphone')}
          style={{ width: '100%' }}
        />
      </FormControl>
      <FormControl
        label={`${translate('Rede social')} (${translate('opcional')})`}
        style={{ marginBottom: 36 }}
        htmlFor='socialMedia'>
        <TextInput
          type='text'
          name='socialMedia'
          id='socialMedia'
          defaultValue={values.socialMedia}
          onChange={handleFormData('socialMedia')}
          style={{ width: '100%' }}
        />
      </FormControl>
      <Button
        rounded
        type='submit'
        variant='primary'
        label={translate('Continue')}
        style={{ width: '100%', justifyContent: 'center' }}
      />
    </Form>
  );
};

export default StepOne;
