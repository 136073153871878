import React, { useEffect } from 'react';
import { Close } from 'grommet-icons';
import ReactPortal from '@components/ReactPortal';
import { ModalHeader, StyledModal } from './Modal.style';

export interface IModalProps {
  isOpen?: boolean;
  showCloseIcon?: boolean;
  handleClose?: any;
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  showCloseIcon = true,
  handleClose,
  ...props
}) => {
  useEffect(() => {
    const closeOnEscapeKey = e => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  return (
    <ReactPortal wrapperId='app_modal'>
      <StyledModal isOpen={isOpen} {...props}>
        <div className='modal-content'>
          <ModalHeader>
            {showCloseIcon && <Close size='medium' style={{ padding: 0 }} onClick={handleClose} />}
          </ModalHeader>
          {children}
        </div>
      </StyledModal>
    </ReactPortal>
  );
};

export default Modal;
