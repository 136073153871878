const appConfig = {
  APP_KEY: process.env.REACT_APP_CHURCH_KEY,
  ZION_API: process.env.REACT_APP_ZION_API,
  APP_AUTH_ENDPOINT: 'auth/application/generate-basic-access-token',

  USER_ENDPOINT: 'profile/detailed',
  LOGIN_ENDPOINT: 'auth/signin',
  RECOVER_PASSWORD_ENDPOINT: 'auth/recover-password',
  SIGN_UP_ENDPOINT: 'auth/signup',
  PRIVACY_POLICY: 'auth/policy',

  GROUP_BASE_ENDPOINT: 'home-groups/explorer/home-group',
  GROUP_ENDPOINT: 'home-groups/explorer/list',
  GROUP_TYPES_ENDPOINT: 'home-groups/explorer/filters/categories',
  GROUP_INFO_ENDPOINT: 'home-groups/explorer/home-group',
  GROUP_MEMBER_INTEREST_EMAIL_ENDPOINT: 'mark-members-interest',

  CHECK_IN_ENDPOINT: 'checkin/do-checkin',

  PLANS_BASE_ENDPOINT: 'plans',
  PLANS_GOALS_ENDPOINT: (planSlug: string) => `plans/plan/${planSlug}/goals`,
  PLANS_SUBSCRIBE_ENDPOINT: (planSlug: string) => `plans/plan/${planSlug}/subscribe`,
  PLANS_GOALS_ITEM_FOLLOWUP_ENDPOINT: (planGoalItemId: string) =>
    `plans/goal-item/${planGoalItemId}/followup`,
  PLANS_GOAL_ITEM_CONTENT_ENDPOINT: (planGoalItemId: string) =>
    `plans/goal/${planGoalItemId}/contents`,
};

export default appConfig;
