import styled, { keyframes } from 'styled-components';
import { Box } from '@zion-tech/ds-web';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const StyledSkeleton = styled(Box)`
  width: ${props => (props.width ? props.width : `100px`)};
  height: ${props => (props.height ? props.height : `44px`)};
  border-radius: ${props => (props.circle ? '999px' : props.radius)};
  background-color: #293446;
  background-image: -webkit-linear-gradient(to right, #141e30, #2d394d);
  background-image: linear-gradient(to right, #293446, #2d394d);
  background-size: 300px 100%;
  background-repeat: no-repeat;
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  ${props =>
    props.light &&
    `
      background-color: #c8cdd5;
      background-image: -webkit-linear-gradient(to right,#141e30,#2d394d);
      background-image: linear-gradient(to right,#c8cdd5,#bfc5cf);
    `}
`;
