import ApiService from '@shared/api/Api.service';
import appConfig from '@shared/config';
import { Success, Error } from '@shared/api/status';
import { IResponse, IResponseEnum, IResponseAPI } from '@shared/api/Api.model';
import { UserService } from '@models/User/User.service';
import { trackIdentify } from '@shared/tracking/segment/Segment.service';
import {
  IAppAccessService,
  ILoginService,
  IRestorePasswordService,
  ISignUpService,
} from './Auth.model';
import useAuthContext from './Auth.context';

interface ReponseAuthToken extends IResponseAPI {
  accessToken;
}

export const LoginService = async ({ username, password }: ILoginService): Promise<IResponse> => {
  try {
    const response = await ApiService<ReponseAuthToken>({
      method: 'POST',
      endpoint: appConfig.LOGIN_ENDPOINT,
      body: { username, password },
    });

    const authContext = useAuthContext();

    if (
      response?.status === 200 &&
      response?.data?.accessToken &&
      response?.data?.accessToken !== null
    ) {
      authContext.setToken(response?.data?.accessToken, null);
      const userResponse: IResponse = await UserService();
      if (userResponse?.status.type === IResponseEnum.success) {
        delete userResponse?.status;
        delete userResponse?.processStatus;
        authContext.setToken(response?.data?.accessToken, userResponse);

        trackIdentify({
          userId: userResponse?.id,
          name: userResponse?.name,
          email: userResponse?.email || '',
        });
        return Success({ ...userResponse }, 'Bem vindo novamente!');
      }
      return Error(
        response?.data?.processMessage !== null
          ? response?.data?.processMessage
          : 'Não conseguimos buscar o usuário em nossa base.',
      );
    }
    return Error(
      response?.data?.processMessage !== null
        ? response?.data?.processMessage
        : 'Não encontramos nenhum usuário relacionado a esse e-mail.',
    );
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const LogoutService = () => {
  try {
    const authContext = useAuthContext();
    authContext.removeToken();
    if (window) window.location.href = '/login';
    return Success(undefined, 'Até a Próxima!');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const RestorePasswordService = async ({ email }: IRestorePasswordService) => {
  try {
    const response = await ApiService({
      method: 'POST',
      endpoint: appConfig.RECOVER_PASSWORD_ENDPOINT,
      body: { email: email },
    });
    return response?.status === 200
      ? Success(
          undefined,
          'Verifique sua caixa de e-mail, você receberá um e-mail com informações para cadastrar uma nova senha.',
        )
      : Error('Não encontramos nenhum usuário relacionado a esse e-mail.');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const SignUpService = async ({
  name,
  email,
  newBirth,
  ddiCellphone,
  cellphone,
  gender,
  isBaptized,
  password,
  privacyPolice,
  socialMedia,
  howMeet,
}: ISignUpService) => {
  try {
    const response = await ApiService({
      method: 'POST',
      endpoint: appConfig.SIGN_UP_ENDPOINT,
      body: {
        name: name,
        email: email,
        password: password,
        genderId: gender,
        documentType: 0,
        phoneDDI: ddiCellphone,
        phoneNumber: cellphone,
        hasNewBirth: newBirth,
        isBaptized: isBaptized,
        acceptPrivacyPolicies: privacyPolice,
        socialUrl: socialMedia,
        howDidyouMeet: howMeet,
      },
    });

    return response?.status === 200 && response?.data?.processStatus === 0
      ? Success(undefined, 'Cadastro realizado com sucesso!')
      : Error(response?.data?.processMessage || 'Houve um erro no cadastro.');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const privacyPoliceService = async () => {
  try {
    const response = await ApiService({
      endpoint: appConfig.PRIVACY_POLICY,
    });
    if (response?.status === 200 && response?.data?.Privacy) {
      return Success(response?.data, 'Termo de Privacidade');
    }
    return Error('Não encontramos nenhum Termo de Privacidade');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const AppAccessService = async ({ apiKey }: IAppAccessService): Promise<IResponse> => {
  try {
    const authContext = useAuthContext();
    const response = await ApiService<ReponseAuthToken>({
      method: 'POST',
      endpoint: appConfig.APP_AUTH_ENDPOINT,
      config: {
        headers: { 'church-api-key': apiKey },
      },
      ignoreRefreshToken: true,
    });
    if (response?.status === 200 && response?.data?.accessToken) {
      authContext.setToken(response?.data?.accessToken, null);
      return Success(
        { accessToken: response?.data?.accessToken },
        'Aplicação registrada e autenticada!',
      );
    }
    return Error('Erro ao registrar e autenticar Aplicação');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};
