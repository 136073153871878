import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { RouteGuard } from '@shared/auth/AuthGuard';

/* Pages */
import Home from '@pages/Home';
import Login from '@pages/Login';
import NotFound404 from '@pages/404';

/** Group */
import Groups from '@pages/Groups';
import GroupSearchPage from '@pages/Groups/pages/GroupSearchPage';
import GroupDetailsPage from '@pages/Groups/pages/GroupDetailsPage';
import GroupOnlineDetailsPage from '@pages/Groups/pages/GroupOnlineDetailsPage';
import GroupOnlineThanksPage from '@pages/Groups/pages/GroupOnlineThanksPage';
import GroupAuthPage from '@pages/Groups/pages/GroupAuthPage';
import GroupWhatsAppPage from '@pages/Groups/pages/GroupWhatsAppPage';

/** Plans */
import Plans from '@pages/Plans';

/** Checkin */
import CheckIn from '@pages/CheckIn/CheckIn';
import CheckinTypes from '@pages/CheckIn/CheckinTypes';

const Router = () => (
  <Routes>
    <Route path='*' element={<NotFound404 title='Página não encontrada' />} />
    <Route
      path='/'
      element={
        <RouteGuard protectedRoute>
          <Home title='Home' />
        </RouteGuard>
      }
    />
    <Route
      path='login'
      element={
        <RouteGuard>
          <Login title='Login' />
        </RouteGuard>
      }
    />
    <Route
      path='checkin-types'
      element={
        <RouteGuard protectedRoute>
          <CheckinTypes title='Check-in Types' />
        </RouteGuard>
      }
    />
    <Route
      path='checkin/:hash'
      element={
        <RouteGuard>
          <CheckIn title='Check-in' />
        </RouteGuard>
      }
    />
    <Route
      path='plans/:slug'
      element={
        <RouteGuard protectedRoute>
          <Plans title='Plans' />
        </RouteGuard>
      }
    />
    <Route
      path='links'
      element={
        <RouteGuard>
          <Groups title='LINKs' />
        </RouteGuard>
      }>
      <Route path='' element={<GroupSearchPage title='LINKs' />} />
      <Route path='details' element={<GroupDetailsPage title='Detalhes do LINK' />} />
      <Route
        path='online-details'
        element={<GroupOnlineDetailsPage title='Detalhes do LINK Online' />}
      />
      <Route
        path='online-thanks'
        element={<GroupOnlineThanksPage title='LINK Online obrigado' />}
      />
      <Route path='auth' element={<GroupAuthPage title='Login para acessar um LINK' />} />
      <Route path='contact' element={<GroupWhatsAppPage title='Conectar-se a um LINK' />} />
    </Route>
  </Routes>
);

export default Router;
