import React, { useEffect } from 'react';
import { Box, Col, Row, Button, Heading, Text } from '@zion-tech/ds-web';
import { useTranslation } from 'react-i18next';
import { Previous } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGroupSelected } from '@models/Group/Group.state';

const GroupOnlineDetailsPage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleReturnToSearchGroups = () => {
    dispatch(setGroupSelected(undefined));
    navigate(`/links`, { replace: false });
  };

  return (
    <>
      <Button
        icon={<Previous color='white!' size='small' />}
        label={translate('Voltar para a busca de Links')}
        style={{ width: `fit-content` }}
        onClick={() => handleReturnToSearchGroups()}
      />
      <Box pad={{ horizontal: '5%' }}>
        <Row>
          <Col>
            <Heading level={2} style={{ width: '100%', fontWeight: 600 }} textAlign='center'>
              {translate('Links Online')}
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text size='medium' style={{ marginBottom: '36px', display: 'block' }}>
              {translate(
                'Muito obrigada pelo seu interesse em participar dos nossos LINKs, mas nossos LINKs são exclusivamente para membros da Zion Church e para pessoas que estão no processo de transição de igreja e querem nos conhecer.',
              )}
            </Text>
            <Text size='medium'>
              {translate(
                'Como você se interessou pelos nossos Links, entendemos que você quer se capacitar, por isso recomendamos os cursos da Escola ZAO e que continue acompanhando os nossos cultos online.',
              )}
            </Text>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default GroupOnlineDetailsPage;
