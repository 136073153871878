import ReactGA from 'react-ga4';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRD';
const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS;
const hasWindow = typeof window !== 'undefined';

const googleAnalyticsInit = () => {
  if (isProd && googleAnalyticsKey && hasWindow) {
    ReactGA.initialize(googleAnalyticsKey);
  }
};

export default googleAnalyticsInit;
