/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { IPlan } from './Plan.model';

const STATE_PLANS = {
  data: {
    list: undefined as IPlan[],
    selected: undefined as IPlan,
  },
};

/**
 * Reducer
 */
export const PlanState = createSlice({
  name: 'plans',
  initialState: STATE_PLANS,
  reducers: {
    setPlans: (state, action) => {
      state.data.list = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.data.selected = action.payload;
    },
  },
});

/**
 * Selectors
 */
export const get = state => state.plans.data;

export const getPlans = state => state.plans.data;

export const getPlansList = state => state.plans.data?.list as IPlan[];

export const getSelectedPlan = state => state.plans.data?.selected as IPlan;

export const { setPlans, setSelectedPlan } = PlanState.actions;

export default PlanState.reducer;
