import React from 'react';
import styled from 'styled-components';
import { Box, Text, Layer, Button } from '@zion-tech/ds-web';

export const PlanThumbnail = styled.img`
  max-width: 100%;
  height: 180px;
  border-radius: 20px;
  margin-bottom: 42px;
`;

const SubscribeModal = ({ plan, handlePlanSubscribe, handleReturn }) => (
  <Layer modal animate animation='slide' position='center' style={{ minHeight: 600 }}>
    <Box flex justify='between' style={{ padding: `48px 32px` }}>
      <Box
        flex
        direction='column'
        justify='start'
        align='center'
        gap='16px'
        style={{ maxHeight: 100 }}>
        <PlanThumbnail
          src={plan.thumbnail || `${process.env.PUBLIC_URL}/images/nopreview.png`}
          alt={plan.name}
          aria-hidden='true'
        />
        <Text size='large' weight={800}>
          {plan.name}
        </Text>
        <Text size='small'>{plan.description}</Text>
      </Box>
      <Box gap='16px'>
        <Button
          rounded
          label='Iniciar o plano'
          variant='primary'
          size='large'
          onClick={() => handlePlanSubscribe()}
          style={{ justifyContent: 'center' }}
        />
        <Button
          reverse
          rounded
          label='Voltar'
          variant='ghost'
          onClick={() => handleReturn()}
          style={{ justifyContent: 'center' }}
        />
      </Box>
    </Box>
  </Layer>
);

export default SubscribeModal;
