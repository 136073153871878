/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import useAuthContext from '@shared/auth/Auth.context';
import { IUser } from './User.model';

const authContext = useAuthContext();

const STATE_USER = {
  data: authContext.getUserInfo(),
};

/**
 * Reducer
 */
export const UserState = createSlice({
  name: 'user',
  initialState: STATE_USER,
  reducers: {
    updateUser: (state, action: PayloadAction<IUser>) => {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
});

/**
 * Selectors
 */
export const get = (state: { user: typeof STATE_USER }) => state.user;
export const getUser = (state: { user: typeof STATE_USER }) => state.user;
export const { updateUser } = UserState.actions;
export default UserState.reducer;
