import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { Button, Text } from '@zion-tech/ds-web';
import Modal from '@components/Modal';
import StatusMessage from '@components/StatusMessage';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 0;
  gap: 12px;
`;

const CelebrationModal = ({ isOpen, onClose, ...props }) => {
  const { t: translate } = useTranslation();
  const { width, height } = useWindowSize();
  return (
    <>
      <Modal isOpen={isOpen} showCloseIcon={false} handleClose={onClose} {...props}>
        <StatusMessage
          key={1}
          illustration='success'
          style={{ position: 'absolute', top: 0, display: 'flex', marginTop: -140 }}
        />
        <Text
          size='large'
          weight={700}
          style={{ textAlign: 'center', padding: `12px 32px 0px 32px` }}>
          {translate('Parabéns, você completou mais uma etapa!')}
        </Text>
        <Content>
          <Button
            rounded
            variant='primary'
            label={translate('Voltar')}
            style={{ width: '100%', justifyContent: 'center' }}
            onClick={() => onClose(false)}
          />
        </Content>
      </Modal>
      {isOpen && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={50}
          tweenDuration={50}
          style={{ zIndex: 99999 }}
        />
      )}
    </>
  );
};

export default CelebrationModal;
