import { IResponse, IResponseEnum } from './Api.model';

export function Success<T = any>(
  response: T,
  message: string,
  title: string = 'Muito bem, deu tudo certo!',
): IResponse {
  return {
    status: {
      type: IResponseEnum.success,
      title,
      message,
    },
    ...response,
  };
}

export function Error(
  message: string,
  title: string = 'Ops! alguma coisa errada aconteceu',
): IResponse {
  return {
    status: {
      type: IResponseEnum.error,
      title,
      message,
    },
  };
}

export function Warning<T = any>(
  reponse: T,
  message: string,
  title: string = 'Muito bem, deu tudo certo!',
): IResponse {
  return {
    status: {
      type: IResponseEnum.success,
      title,
      message,
    },
    ...reponse,
  };
}

export function Info<T = any>(
  reponse: T,
  message: string,
  title: string = 'Muito bem, deu tudo certo!',
): IResponse {
  return {
    status: {
      type: IResponseEnum.success,
      title,
      message,
    },
    ...reponse,
  };
}
