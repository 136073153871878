import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Logout, Close } from 'grommet-icons';
import { Avatar, Layer, Box, Button, Text } from '@zion-tech/ds-web';
import { FaUserCircle } from 'react-icons/fa';
import { getName } from '@shared/naming';
import { get } from '@models/User/User.state';
import { LogoutService } from '@shared/auth/Auth.service';
import { CloseDrawerIcon, LogoZion, StyledHeader } from './Header.style';
import { LanguageMenu } from './components/LanguageMenu';
import { ProfileAvatar } from './components/ProfileAvatar';

const Header = ({ fixed = false }) => {
  const { t: translate } = useTranslation();
  const user = useSelector(get);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleLogoClick = link => {
    navigate(link, { replace: false });
  };

  return (
    <>
      <StyledHeader fixed={fixed}>
        <div className='header-left'>
          <LogoZion
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt='Zion Online'
            onClick={() => handleLogoClick('/')}
          />
        </div>
        <div className='header-right'>
          <LanguageMenu />
          <ProfileAvatar
            loading={!user?.data?.name.toString()}
            photo={user?.data?.photoUrl}
            onProfileClick={() => setOpen(!open)}
          />
        </div>
      </StyledHeader>

      {open && (
        <Layer
          animate
          animation='slide'
          full='vertical'
          modal={false}
          position='right'
          onClickOutside={() => setOpen(!open)}
          onEsc={() => setOpen(!open)}>
          <CloseDrawerIcon onClick={() => setOpen(!open)}>
            <Close size='medium' />
          </CloseDrawerIcon>
          <Box
            flex
            fill='vertical'
            overflow='auto'
            width='medium'
            pad={{ horizontal: 'small', top: 'large', bottom: '80px' }}
            justify='between'>
            <Box
              flex
              direction='row'
              justify='start'
              align='center'
              gap='16px'
              style={{ maxHeight: 100 }}>
              {user?.data?.photoUrl ? (
                <Avatar
                  src={user?.data?.photoUrl}
                  style={{ width: 80, height: 80, marginLeft: 24 }}
                />
              ) : (
                <FaUserCircle size={80} color='#FFFFFF' style={{ marginLeft: 24 }} />
              )}

              <Box style={{ width: 180 }} gap='2px'>
                <Text
                  size='medium'
                  color='white!'
                  weight='bold'
                  style={{ textTransform: 'capitalize' }}>
                  {`${getName(user?.data?.name).first} ${getName(user?.data?.name).last}`}
                </Text>
                <Text size='xsmall' color='grey!'>
                  {user?.data?.email}
                </Text>
              </Box>
            </Box>
            <Box>
              <Button
                reverse
                rounded
                icon={<Logout />}
                label={translate('Sair da minha conta')}
                variant='ghost'
                onClick={() => LogoutService()}
                style={{ justifyContent: 'center' }}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default Header;
