import React, { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HelpOption } from 'grommet-icons';
import { Button, Form, FormControl, TextInput, Box, RadioButtonGroup } from '@zion-tech/ds-web';
import { SelectField, SelectInput } from '@components/Select/Select.styles';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import { removeBar, firstLetterUpper } from '@shared/naming';
import { InputTipField, InputTip, TipInput } from './SignUpForm.style';

const StepTwo = ({ nextStep, handleFormData, prevStep, suggestionSelect, values }) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const [status, setStatus] = useState({
    type: '',
    message: '',
  });

  const submitFormData = async e => {
    e.preventDefault();
    if (!(await validate())) return;
    const { value } = e;
    trackEvent({
      type: 'info',
      name: `ZionOnline:${firstLetterUpper(removeBar(location.pathname))}:Cadastro:Passo2`,
      value: {
        sexo: value?.gender,
        eCristao: value.isChristian,
        possuiNovoNascimento: value.newBirth,
        possuiBastismo: value.isBaptized,
        comoConheceuZion: value.howMeet,
      },
    });
    nextStep();
  };

  const validate = async () => {
    const schemaTwo = yup.object().shape({
      isBaptized: yup.string().required('O campo é obrigatório'),
      newBirth: yup.string().required('O campo é obrigatório'),
    });

    const schema = yup.object().shape({
      howMeet: yup.string().required('O campo é obrigatório'),
      isChristian: yup.string().required('O campo é obrigatório'),
      gender: yup.string().required('O campo é obrigatório'),
    });

    try {
      await schema.validate(values);
      if (values.isChristian === translate('Sim')) {
        await schemaTwo.validate(values);
      }
      return true;
    } catch (err) {
      setStatus({
        type: err.path,
        message: err.errors,
      });
      return false;
    }
  };

  return (
    <Form onSubmit={submitFormData} style={{ width: 310 }}>
      <FormControl
        label={translate('Sexo')}
        style={{ marginBottom: 16, fontSize: 14 }}
        htmlFor='gender'
        error={status.type === 'gender'}
        message={status.type === 'gender' ? translate(status.message) : null}>
        <RadioButtonGroup
          name='gender'
          options={[
            { label: translate('Masculino').toString(), value: '1' },
            { label: translate('Feminino').toString(), value: '0' },
          ]}
          value={values.gender}
          onChange={handleFormData('gender')}
          style={{ width: '100%' }}
        />
      </FormControl>
      <FormControl
        label={translate('Você é cristão evangélico?')}
        style={{ marginBottom: 16, fontSize: 14 }}
        htmlFor='isChristian'
        error={status.type === 'isChristian'}
        message={status.type === 'isChristian' ? translate(status.message) : null}>
        <TextInput
          type='text'
          name='isChristian'
          id='isChristian'
          value={values.isChristian}
          onSuggestionSelect={suggestionSelect('isChristian')}
          suggestions={[translate('Sim'), translate('Não')]}
          style={{ width: '100%' }}
        />
      </FormControl>

      {values.isChristian === translate('Sim') && (
        <>
          <FormControl
            label={translate('Você teve o novo nascimento?')}
            style={{ marginBottom: 16, fontSize: 12, padding: 2 }}
            htmlFor='newBirth'
            error={status.type === 'newBirth'}
            message={status.type === 'newBirth' ? translate(status.message) : null}>
            <InputTipField direction='row' align='center'>
              <InputTip
                plain
                type='text'
                name='newBirth'
                id='newBirth'
                value={values.newBirth}
                onSuggestionSelect={suggestionSelect('newBirth')}
                suggestions={[translate('Sim'), translate('Não')]}
                style={{ width: '100%' }}
              />
              <Box style={{ width: 50 }}>
                <TipInput
                  content={translate('Aceitou Jesus como único e verdadeiro salvador da sua vida.')}
                  dropProps={{
                    align: { top: 'bottom' },
                    inline: true,
                    style: {
                      fontSize: 12,
                      fontFamily: 'inherit',
                      fontWeight: 'bold',
                    },
                  }}>
                  <HelpOption style={{ width: 18, marginRight: 8 }} />
                </TipInput>
              </Box>
            </InputTipField>
          </FormControl>
          <FormControl
            label={translate('Você foi batizado nas águas?')}
            style={{ marginBottom: 16 }}
            htmlFor='isBaptized'
            error={status.type === 'isBaptized'}
            message={status.type === 'isBaptized' ? translate(status.message) : null}>
            <InputTipField direction='row' align='center'>
              <InputTip
                plain
                type='text'
                name='isBaptized'
                id='isBaptized'
                value={values.isBaptized}
                onSuggestionSelect={suggestionSelect('isBaptized')}
                suggestions={[translate('Sim'), translate('Não')]}
                style={{ width: '100%' }}
              />
              <Box style={{ width: 50 }}>
                <TipInput
                  content={translate('Batizado por nas águas por imersão completa.')}
                  dropProps={{
                    align: { top: 'bottom' },
                    inline: true,
                    style: {
                      fontSize: 12,
                      fontFamily: 'inherit',
                      fontWeight: 'bold',
                    },
                  }}>
                  <HelpOption style={{ width: 18, marginRight: 8 }} />
                </TipInput>
              </Box>
            </InputTipField>
          </FormControl>
        </>
      )}

      <FormControl
        label={translate('Como você conheceu a Zion Church')}
        style={{ marginBottom: 36 }}
        error={status.type === 'howMeet'}
        message={status.type === 'howMeet' ? translate(status.message) : null}>
        <SelectField htmlFor='howMeet'>
          <SelectInput
            name='howMeet'
            id='howMeet'
            options={[
              translate('Selecione'),
              'Google',
              'Youtube',
              'Instagram',
              translate('Amigo(a)'),
              translate('Outro'),
            ]}
            value={values.howMeet}
            onChange={handleFormData('howMeet')}
            style={{ width: '100%' }}
          />
        </SelectField>
      </FormControl>

      <Box style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button
          rounded
          type='submit'
          variant='secondary'
          label={translate('Voltar')}
          onClick={prevStep}
          style={{ width: '48%', justifyContent: 'center' }}
        />
        <Button
          rounded
          type='submit'
          variant='primary'
          label={translate('Continue')}
          style={{ width: '48%', justifyContent: 'center' }}
        />
      </Box>
    </Form>
  );
};

export default StepTwo;
