import StatusMessage from '@components/StatusMessage';
import { Page } from '@components/index';
import { ICheckIn } from '@models/CheckIn/CheckIn.model';
import { CheckInService } from '@models/CheckIn/CheckIn.service';
import { getUser } from '@models/User/User.state';
import { showAlert } from '@shared/alerts';
import AuthForm from '@shared/auth/AuthForm';
import { getName } from '@shared/naming';
import { Box, Button, Row } from '@zion-tech/ds-web';
import { Buffer } from 'buffer';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAuthContext from '@shared/auth/Auth.context';
import { B, CheckInSidebar, H5, LogoZion, P, SPAN, STRONG } from './CheckIn.style';

enum CheckinStage {
  NOT_STARTED,
  AUTHENTICATE,
  DONE,
}

const CheckIn = ({ title }) => {
  const { t: translate } = useTranslation();
  const { hash } = useParams();
  const user = useSelector(getUser);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [stage, setStage] = useState<CheckinStage>(CheckinStage.NOT_STARTED);
  const authContext = useAuthContext();

  useEffect(() => {
    document.title = title;
  }, []);

  async function doCheckin() {
    setLoading(true);
    const response = await CheckInService({
      eventData: hash,
    });

    if (response.status.type === 'success') {
      setStage(CheckinStage.DONE);
    } else {
      showAlert({
        message: response?.response?.processMessage || 'Ocorreu uma falha ao registar a presença',
        type: 'error',
      });
    }

    setLoading(false);
  }

  async function doCheckinOrAuthenticate() {
    if (!authContext.isUserAuthenticated()) {
      setStage(CheckinStage.AUTHENTICATE);
    } else {
      await doCheckin();
    }
  }

  const contentHash: ICheckIn = useMemo(() => {
    const decode = Buffer.from(hash, 'base64').toString('utf8').split(';');
    const [churchId, eventId, eventName, encounterId, encounterName, startDate, endDate] = decode;
    return {
      churchId: parseInt(churchId, 10),
      eventId: parseInt(eventId, 10),
      eventName,
      encounterId: parseInt(encounterId, 10),
      encounterName,
      startDate,
      endDate,
    };
  }, [hash]);

  function getContent() {
    switch (stage) {
      case CheckinStage.NOT_STARTED:
        return (
          <Box justify='center' align='center' width='80%' style={{ marginBottom: 56 }}>
            <Box justify='center' align='center'>
              <H5>Confirmar participação</H5>
              <P>Evento</P>
              <STRONG>{contentHash.eventName}</STRONG>
              {contentHash.encounterName && (
                <>
                  <P>Encontro</P>
                  <STRONG>{contentHash.encounterName}</STRONG>
                </>
              )}
            </Box>
            <Button
              rounded
              loading={isLoading}
              variant='primary'
              label={translate('Registrar presença')}
              style={{ width: '80%', marginTop: 86, justifyContent: 'center', zIndex: 1 }}
              onClick={() => doCheckinOrAuthenticate()}
            />
          </Box>
        );

      case CheckinStage.AUTHENTICATE:
        return (
          <AuthForm
            showTitle
            showSubtitle
            onSuccess={() => doCheckin()}
            onError={event => showAlert(event)}
          />
        );

      case CheckinStage.DONE:
        return (
          <Box justify='center' align='center' width='80%' style={{ marginBottom: 56 }}>
            <STRONG style={{ marginTop: 35, fontWeight: 600 }}>
              Presença registrada com sucesso
            </STRONG>
            <StatusMessage
              key={1}
              illustration='success'
              style={{ display: 'flex', height: '90', marginTop: 24 }}
            />
            <Box justify='center' align='center'>
              <P>Evento</P>
              <STRONG>{contentHash.eventName}</STRONG>
              <STRONG>
                <P>Check-in de:</P> {getName(user?.data?.name).all}
              </STRONG>
              <P>{moment().format('DD/MM/YYYY HH:mm')}</P>

              {contentHash.encounterName && (
                <STRONG style={{ marginTop: 35, fontWeight: 400 }}>
                  Encontro - {contentHash.encounterName}
                </STRONG>
              )}
            </Box>
          </Box>
        );

      default:
        return null;
    }
  }

  return (
    <Page fluid fullHeight>
      <Row>
        <CheckInSidebar md={6} lg={5} xl={4} xxl={3}>
          <Box flex justify='center' align='center' width='100%' height='100%'>
            <Box direction='row' justify='center' align='center' style={{ marginBottom: 56 }}>
              <LogoZion src={`${process.env.PUBLIC_URL}/logo.png`} alt='Zion Church Online' />
              <B>ZION</B> <SPAN>Church</SPAN>
            </Box>
            {getContent()}
          </Box>
        </CheckInSidebar>
      </Row>
    </Page>
  );
};

export default CheckIn;
