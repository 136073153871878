import React from 'react';
import { StyledSkeleton } from './Skeleton.style';

export interface ISkeletonProps {
  children?: JSX.Element;
  loading?: boolean;
  circle?: boolean;
  light?: boolean;
  radius?: string;
  width?: string;
  height?: string;
  style?: any;
  props?: any;
}

const Skeleton: React.FC<ISkeletonProps> = ({
  loading = true,
  width = '100px',
  height = '44px',
  circle = false,
  light = false,
  radius = '4px',
  children = undefined,
  style = undefined,
  ...props
}) =>
  loading ? (
    <StyledSkeleton
      width={width}
      height={height}
      circle={circle}
      style={style}
      light={light}
      radius={radius}
      {...props}
    />
  ) : (
    children
  );

export default Skeleton;
