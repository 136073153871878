import React from 'react';
import Lottie from 'react-lottie';
import { Text, Box, Button } from '@zion-tech/ds-web';
import { Container } from './StatusMessage.style';
import Illustrations from './Illustrations';

export interface IStatusMessageProps {
  title?: string;
  titleSize?: string;
  titleWeight?: any;
  description?: string;
  descriptionSize?: string;
  illustration?: string;
  style?: any;
  boxStyle?: any;
  buttonLabel?: string;
  buttonVariant?: any;
  onButtonClick?: any;
}

const StatusMessage: React.FC<IStatusMessageProps> = ({
  title = '',
  titleSize = 'xlarge',
  titleWeight = 800,
  description = '',
  descriptionSize = 'medium',
  illustration = 'success',
  style = {},
  boxStyle = {},
  buttonLabel = undefined,
  buttonVariant = 'secondary',
  onButtonClick = undefined,
  children,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Illustrations[illustration],
  };
  return (
    <Container
      align='center'
      justify='center'
      style={{ width: '100%', height: '100%', textAlign: 'center', ...style }}>
      <Box align='center' gap='18px' style={{ width: 400, ...boxStyle }}>
        <Lottie options={defaultOptions} height={120} width={120} />
        {title && (
          <Text size={titleSize} weight={titleWeight}>
            {title}
          </Text>
        )}
        {description && <Text size={descriptionSize}>{description}</Text>}

        {children}

        {buttonLabel && (
          <Button
            rounded
            variant={buttonVariant}
            label={buttonLabel}
            margin={{ top: 'large' }}
            onClick={onButtonClick}
          />
        )}
      </Box>
    </Container>
  );
};

export default StatusMessage;
