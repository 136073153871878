import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@zion-tech/ds-web';
import { getPlansList, getSelectedPlan, setPlans, setSelectedPlan } from '@models/Plan/Plan.state';
import { IResponseEnum } from '@shared/api/Api.model';
import { Header, Page } from '@components/index';
import { IPlan, IPlanGoal } from '@models/Plan/Plan.model';
import { getDate } from '@shared/date';
import { HorizontalCalendar, SubscribeModal } from '@pages/Plans/components';
import { PlanService, PlansService, PlanSubscribeService } from '@models/Plan/Plan.service';
import { PlanOrderSelector } from '@pages/Plans/Plans.style';
import Skeleton from '@components/Skeleton';
import GoalsList from './components/GoalsList/GoalsList';

const Plans = ({ title }) => {
  const navigate = useNavigate();
  const dispach = useDispatch();
  const location = useLocation();
  const listRef = React.createRef();
  const plans = useSelector(getPlansList);
  const plan = useSelector(getSelectedPlan);

  useEffect(() => {
    document.title = title;
    getPlanDetails();
  }, []);

  useEffect(() => {
    if (plan && plan?.goals) {
      const getLastCompletedGoal = plan?.goals.filter(goal => goal.completed)?.reverse()[0];
      scollToGoal(getLastCompletedGoal);
    }
  }, [plan?.goals, listRef]);

  const getPlanDetails = async () => {
    if (plan) {
      await handleGetPlanGoals(plan);
    } else {
      const planSlug = location?.pathname.replace('/plans/', '');
      const plansResponse = await PlansService();
      if (plansResponse.status.type === IResponseEnum.success && plansResponse?.plans) {
        dispach(setPlans(plansResponse?.plans));
        const selectedPlan = plansResponse?.plans?.filter((p: IPlan) => p.slug === planSlug)[0];
        if (selectedPlan) await handleGetPlanGoals(selectedPlan);
        else navigate('/', { replace: false });
      }
    }
  };

  const getInitialDate = () => {
    if (plan?.goals) {
      const getLastCompletedGoal = plan.goals.filter(goal => goal.completed)?.reverse()[0];
      return getLastCompletedGoal
        ? getDate(getLastCompletedGoal.date)
        : getDate(plan?.goals[0]?.date);
    }
    return getDate();
  };

  const scollToGoal = (goal: IPlanGoal) => {
    if (goal) {
      const currentRef: any = listRef?.current;
      currentRef?.scrollToItem(goal?.order, 'end');
    }
  };

  const handleGetPlanGoals = async selectedPlan => {
    const response = await PlanService(selectedPlan?.slug);
    if (response.status.type === IResponseEnum.success) {
      const planWithGoals = { ...selectedPlan, goals: response?.goals };
      dispach(setSelectedPlan(planWithGoals));
    }
  };

  const handlePlanSubscribe = async () => {
    const response = await PlanSubscribeService(plan?.slug);
    if (response.status.type === IResponseEnum.success) {
      const subscribedPlan: IPlan = { ...plan, subscribed: true };
      const updatedPlans = plans.map(p =>
        p.id === subscribedPlan.id ? { ...subscribedPlan, goals: [] } : p,
      );
      dispach(setSelectedPlan(subscribedPlan));
      dispach(setPlans(updatedPlans));
    }
  };

  return (
    <>
      <Header fixed />
      <Page fluid>
        <Grid>
          <Box flex direction='row'>
            <PlanOrderSelector>
              {plan && plan?.goals ? (
                <HorizontalCalendar
                  goals={plan?.goals}
                  initialDate={getInitialDate()}
                  onSelect={(goal: IPlanGoal) => scollToGoal(goal)}
                />
              ) : (
                <Skeleton loading width='80%' height='56px' style={{ alignSelf: 'center' }} />
              )}
            </PlanOrderSelector>
          </Box>
          <Box flex direction='row'>
            <GoalsList ref={listRef} />
          </Box>
        </Grid>
      </Page>
      {plan && !plan.subscribed && (
        <SubscribeModal
          plan={plan}
          handlePlanSubscribe={() => handlePlanSubscribe()}
          handleReturn={() => navigate('/', { replace: false })}
        />
      )}
    </>
  );
};

export default Plans;
