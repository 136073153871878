import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Calendar, Clock, Group, User } from 'grommet-icons';
import { Box, Avatar, Text, Row, Col, Button } from '@zion-tech/ds-web';
import { IGroup, WeekdayEnum } from '@models/Group/Group.model';
import { GroupInfoService } from '@models/Group/Group.service';
import { getSelected, setGroupSelected } from '@models/Group/Group.state';
import { IResponseEnum } from '@shared/api/Api.model';
import { CicloTag } from '@pages/Groups/components/CicloTag';
import { getName } from '@shared/naming';
import Skeleton from '@components/Skeleton';

export interface IGroupDetailsProps {
  onButtonClick?: any;
}

const GroupDetails: React.FC<IGroupDetailsProps> = ({ onButtonClick }) => {
  const { t: translate } = useTranslation();
  const dispach = useDispatch();
  const selectedGroup = useSelector(getSelected);
  const [selectedGroupState, setSelectedGroupState]: [IGroup, any] = useState();
  const [groupInfo, setGroupInfo]: [IGroup, any] = useState(undefined);

  useEffect(() => {
    if (selectedGroupState !== selectedGroup) {
      getGroupInfo();
    }
  }, [selectedGroup]);

  const getGroupInfo = async () => {
    const response = await GroupInfoService({ groupId: selectedGroup?.id });
    if (response.status.type === IResponseEnum.success) {
      const info: IGroup = response;
      const selected = {
        ...selectedGroup,
        contact: {
          phone: info?.contact?.phone?.replace(/\D/g, ''),
          email: info?.contact?.email,
        },
      };
      setGroupInfo(info);
      setSelectedGroupState(selected);
      dispach(setGroupSelected(selected));
    }
  };

  const infoForLinksOnline = selectedGroup && selectedGroup?.categoryName === 'LINKS - ONLINE' && (
    <>
      <Row>
        <Col>
          <Box style={{ gap: '16px' }}>
            <Text>
              {translate("Link's Online são")} <b> {translate('exclusivamente')} </b>
              {translate(
                'para membros da Zion Church e pessoas que estão transacionando para nossa igreja.',
              )}
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col style={{ margin: `42px 0` }}>
          <hr style={{ opacity: 0.2 }} />
        </Col>
      </Row>
    </>
  );

  return (
    <Box pad={{ horizontal: '5%' }} style={{ minHeight: 'auto' }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <Box flex direction='row' pad={{ left: '8px', bottom: `24px` }}>
                {groupInfo?.managers ? (
                  groupInfo?.managers.map(leader => (
                    <Avatar
                      key={leader?.photoUrl}
                      size='xlarge'
                      src={leader?.photoUrl}
                      margin={{ horizontal: '-8px' }}
                    />
                  ))
                ) : (
                  <>
                    <Skeleton
                      loading
                      circle
                      width='96px'
                      height='96px'
                      style={{ marginLeft: '-8px' }}
                    />
                    <Skeleton
                      loading
                      circle
                      width='96px'
                      height='96px'
                      style={{ marginLeft: '-8px' }}
                    />
                  </>
                )}
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <Box flex direction='column' gap='8px'>
                <Text size='large' weight='bold'>
                  {selectedGroup.name}
                </Text>
                <Text size='medium' style={{ paddingTop: `4px` }}>
                  {selectedGroup?.categoryName}
                </Text>
                <CicloTag ciclo={selectedGroup?.statusName} />
              </Box>
            </Col>
          </Row>
        </Col>
        <Col sm={5}>
          <Box flex direction='row' justify='end' align='end' style={{ height: `100%` }}>
            <Button
              rounded
              disabled={selectedGroup?.statusName === 'LOTADO'}
              variant='primary'
              label={translate('Quero participar')}
              style={{ width: '100%', marginTop: 16, justifyContent: 'center', zIndex: 1 }}
              onClick={onButtonClick}
            />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col style={{ margin: `42px 0` }}>
          <hr style={{ opacity: 0.2 }} />
        </Col>
      </Row>
      {infoForLinksOnline}
      <Row>
        <Col>
          <Box style={{ gap: '16px' }}>
            {selectedGroup?.managers && (
              <Text
                style={{
                  display: `flex`,
                  flexWrap: 'wrap',
                  alignItems: `center`,
                  textTransform: 'capitalize',
                }}>
                <User size='medium' style={{ paddingRight: 8 }} />
                <b>{translate('Líderes')}:</b> &nbsp;
                {selectedGroup?.managers
                  .map(
                    leader =>
                      `${getName(Object.keys(leader).length !== 0 ? leader?.name : 'Zion').first} 
                      ${getName(Object.keys(leader).length !== 0 ? leader?.name : 'LINK').last}`,
                  )
                  .join(' & ')}
              </Text>
            )}
            <Text style={{ display: `flex`, alignItems: `center` }}>
              <Calendar size='medium' style={{ paddingRight: 8 }} />
              <b>{translate('Dia')}:</b> &nbsp;
              {WeekdayEnum[selectedGroup?.operationDays?.[0]?.dayId]}
            </Text>
            <Text style={{ display: `flex`, alignItems: `center` }}>
              <Clock size='medium' style={{ paddingRight: 8 }} />
              <b>{translate('Horário')}:</b> &nbsp;
              {selectedGroup?.operationDays?.[0].startAt} {translate('a')}{' '}
              {selectedGroup?.operationDays?.[0].endAt}
            </Text>
            <Text style={{ display: `flex`, alignItems: `center` }}>
              <Group size='medium' style={{ paddingRight: 8 }} />
              <b>{translate('Participantes')}:</b> &nbsp;
              {groupInfo?.totalMembers || 0} {translate('pessoas')}
            </Text>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

export default GroupDetails;
