import styled from 'styled-components';
import { Select, Box } from '@zion-tech/ds-web';

export const SelectField = styled(Box)`
  overflow: hidden !important;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0px 2px 5px #0000006e;
  border: 1px solid
    ${props =>
      props.error
        ? props.theme.global.colors.feedback3
        : props.theme.global.colors.border[props.theme.dark ? 'dark' : 'light']};

  button {
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: none !important;
    overflow: hidden !important;
  }
`;

export const SelectInput = styled(Select)`
  height: 30px;
  box-shadow: none !important;
  input {
    box-shadow: none !important;
  }
`;
