import React, { useState } from 'react';
import { Moment } from 'moment';
import { IPlanGoal } from '@models/Plan/Plan.model';
import { getDate } from '@shared/date';
import Skeleton from '@components/Skeleton';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons/Chevron';
import { NavButton, StyledDayButton, StyledDaysList, StyledDay } from './CalendarDays.style';
import { Week } from './types';

type ICalendarDaysProps = {
  goals: IPlanGoal[];
  currentDate: Moment;
  weekDays: Week | null | undefined;
  onChange: any;
  onSelect: any;
};

const CalendarDays = ({ goals, weekDays, currentDate, onChange, onSelect }: ICalendarDaysProps) => {
  const [selected, setSelected] = useState(getDate());

  const handleSelectDay = (day: Moment) => {
    const currentGoalByDate: IPlanGoal = getGoalByDate(day);
    if (currentGoalByDate) onSelect(currentGoalByDate);
    setSelected(day);
  };

  const getGoalByDate = (day: Moment) =>
    goals?.filter(goal => goal?.fullDate?.doy === `${day.year()}-${day.dayOfYear()}` && goal)[0];

  const isLate = (day: Moment) => {
    if (goals) {
      const currentGoalByDate: IPlanGoal = getGoalByDate(day);
      if (
        currentGoalByDate &&
        day.year() === getDate().year() &&
        day.dayOfYear() < getDate().dayOfYear() &&
        !currentGoalByDate?.completed
      )
        return true;
    }
    return false;
  };

  const isCompleted = (day: Moment) => {
    if (goals) {
      const currentGoalByDate: IPlanGoal = getGoalByDate(day);
      if (currentGoalByDate?.completed) return true;
    }
    return false;
  };

  return weekDays ? (
    <StyledDaysList className='calendar-days'>
      <NavButton onClick={() => onChange(currentDate.subtract(7, 'days'))}>
        <ChevronLeftIcon />
      </NavButton>
      {weekDays &&
        weekDays.map((day: Moment) => (
          <StyledDay key={`key-${day.calendar()}`}>
            <StyledDayButton
              onClick={() => handleSelectDay(day)}
              selected={day?.dayOfYear() === selected?.dayOfYear()}
              completed={isCompleted(day)}
              late={isLate(day)}>
              <span className='day-number'>{day.format('DD')}</span>
              <span>{day.format('MMM')}</span>
            </StyledDayButton>
          </StyledDay>
        ))}
      <NavButton onClick={() => onChange(currentDate.add(7, 'days'))}>
        <ChevronRightIcon />
      </NavButton>
    </StyledDaysList>
  ) : (
    <Skeleton loading width='80%' height='56px' style={{ alignSelf: 'center' }} />
  );
};

export default React.memo(CalendarDays);
