import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from '@zion-tech/ds-web';
import { GroupStatusEnum } from '@models/Group/Group.model';

export const StyledTag = styled.span`
  width: max-content;
  padding: 0px 12px 4px 12px;
  border-radius: 8px;
  background: ${props => (props.status === 'open' ? '#85D697' : '#D68585')};
`;

export const StyledDot = styled.span`
  width: 2px;
  height: 2px;
  padding: 4px;
  border-radius: 500px;
  background: ${props => (props.status === 'open' ? '#85D697' : '#D68585')};
`;

export interface ICicloTagProps {
  ciclo: string;
  dot?: boolean;
  literal?: boolean;
}

export const CicloTag: React.FC<ICicloTagProps> = ({ ciclo, dot = false, literal = false }) => {
  const { t: translate } = useTranslation();
  return !dot ? (
    <StyledTag status={ciclo === 'LOTADO' ? 'closed' : 'open'}>
      <Text size='12px' weight={600} color='#000'>
        {literal
          ? translate(ciclo)
          : translate(GroupStatusEnum[ciclo] || GroupStatusEnum['RECEBENDO PARTICIPANTES'])}
      </Text>
    </StyledTag>
  ) : (
    <StyledDot status={ciclo === 'LOTADO' ? 'closed' : 'open'} />
  );
};
