import React from 'react';
import styled from 'styled-components';
import { Button } from '@zion-tech/ds-web';
import { Spotify } from 'grommet-icons';

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 80px;
  right: 24px;
  z-index: 2;
  background: #3daeab;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 14px;
  display: block;
  box-shadow: none;
`;

export interface IFloatButtonProps {
  onClick?: any;
}

const SPOTIFY_PLAN =
  'https://open.spotify.com/show/1T45yelqay2wurvVuDaf9w?si=1zpJExrYRR24X_XqL43aQQ';

const FloatButton: React.FC<IFloatButtonProps> = ({ onClick }) => (
  <a href={SPOTIFY_PLAN} target='blank' onClick={() => onClick()}>
    <StyledButton rounded variant='primary' icon={<Spotify color='#FFF' />} tabIndex={0} />
  </a>
);

export default React.memo(FloatButton);
