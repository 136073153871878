import ApiService from '@shared/api/Api.service';
import { IResponse, IResponseAPI } from '@shared/api/Api.model';
import { Success, Error } from '@shared/api/status';
import appConfig from '@shared/config';

export const UserService = async (): Promise<IResponse> => {
  try {
    const response = await ApiService<IResponseAPI>({
      method: 'GET',
      endpoint: appConfig.USER_ENDPOINT,
    });
    return response?.status === 200 && response?.data?.details
      ? Success({ ...response?.data?.details }, 'Seja bem vindo!', undefined)
      : Error('Não encontramos nenhum usuário em nossa base.');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};
