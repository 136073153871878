import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fadeOutLeft, fadeInLeft } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import { useLocation } from 'react-router-dom';
import { removeBar, firstLetterUpper } from '@shared/naming';
import { showAlert } from '@shared/alerts';
import LoginForm from '../LoginForm';
import RestorePasswordForm from '../RestorePasswordForm';
import SignUpForm from '../SignUpForm';

const AuthForm = ({
  showTitle = false,
  showSubtitle = false,
  shouldRedirect = false,
  redirectTo = '/',
  onSuccess = undefined,
  onError = undefined,
}) => {
  const { t: translate } = useTranslation();
  const [screen, setScreen] = useState('login');
  const [motionDispach, setMotionDispach] = useState(undefined);
  const location = useLocation();

  const handleChangeScreen = s => {
    setMotionDispach(fadeOutLeft);
    setTimeout(() => {
      setScreen(s);
      setMotionDispach(fadeInLeft);
    }, 800);
    trackEvent({
      type: 'info',
      name: `ZionOnline:${firstLetterUpper(removeBar(location.pathname))}:Cadastrar`,
      value: undefined,
    });
  };

  const handleSuccessAndReturnToLogin = status => {
    if (status?.message) showAlert({ type: 'success', message: translate(status?.message) });
    handleChangeScreen('login');
  };

  const BoxWithMotion = styled.div`
    animation: 0.8s ${motionDispach ? keyframes`${motionDispach}` : `none`};
    transition: all 0.6s;
  `;

  const renderScreen = () => {
    const screens = {
      'login': (
        <LoginForm
          showTitle={showTitle}
          shouldRedirect={shouldRedirect}
          redirectTo={redirectTo}
          onSuccess={onSuccess}
          onError={onError}
          onClickRestorePassword={() => handleChangeScreen('restore-password')}
          onClickSignUp={() => handleChangeScreen('signup')}
        />
      ),
      'restore-password': (
        <RestorePasswordForm
          showCloseButton
          showTitle={showTitle}
          showSubtitle={showSubtitle}
          onError={onError}
          onSuccess={e => handleSuccessAndReturnToLogin(e)}
          onCloseButtonClick={() => handleChangeScreen('login')}
        />
      ),
      'signup': (
        <SignUpForm
          showCloseButton
          showTitle={showTitle}
          onSuccess={e => handleSuccessAndReturnToLogin(e)}
          onError={onError}
          onCloseButtonClick={() => handleChangeScreen('login')}
        />
      ),
    };

    return screens[screen] || screens.login;
  };

  return <BoxWithMotion align='center'>{renderScreen()}</BoxWithMotion>;
};

export default AuthForm;
