import React from 'react';
import { ThemeProvider } from '@zion-tech/ds-web';
import { zion } from '@zion-tech/ds-tokens';
import { GlobalStyle } from '@components/index';
import Alerts from '@shared/alerts';
import Router from '@pages/Router';

/* Tracking Web Application */
import { Segment, Sentry } from '@shared/tracking';
import usePageTracking from '@shared/tracking/usePageTracking';
import googleAnalyticsInit from '@shared/tracking/google-analytics';

function App() {
  googleAnalyticsInit();
  usePageTracking();
  return (
    <ThemeProvider theme={{ ...zion }}>
      <Segment />
      <GlobalStyle />
      <Router />
      <Alerts />
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App, { name: 'ZionOnline' });
