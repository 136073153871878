import axios from 'axios';
import appConfig from '@shared/config';
import useAuthContext from '@shared/auth/Auth.context';
import { AppAccessService } from '@shared/auth/Auth.service';
import { IResponse, IResponseAPI, IResponseEnum } from './Api.model';
import registerMocks from '../mocks';

interface IApiService {
  domain?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PUSH' | 'DELETE';
  endpoint?: string;
  params?: any;
  body?: any;
  config?: any;
  token?: string;
  ignoreRefreshToken?: boolean;
}

registerMocks(axios);

const refreshAppToken = async () => {
  const response: IResponse = await AppAccessService({ apiKey: appConfig.APP_KEY });
  if (response.status.type === IResponseEnum.success) return true;
  return false;
};

async function ApiService<T = any>({
  domain = appConfig.ZION_API,
  method = 'GET',
  endpoint,
  params,
  body,
  config,
  token,
  ignoreRefreshToken,
}: IApiService): Promise<T> {
  const authContext = useAuthContext();

  if (!ignoreRefreshToken && authContext.isTokenExpired()) await refreshAppToken();

  const requestConfig = {
    method: method,
    url: `${domain}/${endpoint}`,
    data: body && JSON.stringify(body),
    params,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token || authContext.getToken()}`,
      ...config?.headers,
    },
  };
  delete requestConfig.headers.host;
  return axios(requestConfig)
    .then((result: IResponseAPI) => ({
      status: result?.status,
      data: {
        ...result?.data?.response,
        ...result?.data?.data,
      },
    }))
    .catch(error => error.response);
}

export default ApiService;
