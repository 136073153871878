import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '@shared/alerts';
import AuthForm from '@shared/auth/AuthForm';
import { Page } from '@components/index';
import { LoginContent, LogoZion } from './Login.style';

const Login = ({ title }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, []);

  const handleLogin = event => {
    navigate('/', { replace: false });
    showAlert(event);
  };

  return (
    <Page fluid fullHeight style={{ display: 'flex', justifyContent: 'center' }}>
      <LoginContent className='content'>
        <LogoZion src={`${process.env.PUBLIC_URL}/logo.png`} alt='Zion Church Online' />
        <AuthForm
          showTitle
          showSubtitle
          shouldRedirect
          onError={event => showAlert(event)}
          onSuccess={event => handleLogin(event)}
        />
      </LoginContent>
    </Page>
  );
};

export default Login;
