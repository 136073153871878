import React from 'react';
import { Box, Card, CardBody, Image, Text, Button } from '@zion-tech/ds-web';
import styled from 'styled-components';

export const StyledCard = styled(Card)``;

export const CardImage = ({
  icon = undefined,
  title = undefined,
  description = undefined,
  buttonLabel = undefined,
  image = 'https://images.unsplash.com/photo-1501594907352-04cda38ebc29?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2389&q=80',
  onButtonClick = undefined,
}) => (
  <StyledCard>
    <Box height='small' width='medium'>
      <Image src={image} fit='cover' />
    </Box>
    <CardBody gap='small' pad={{ horizontal: '30px', vertical: '30px' }}>
      <Box pad={{ bottom: `12px` }}>
        {title && (
          <Text
            color='text-strong'
            size='large'
            weight='bolder'
            margin={{ bottom: '12px' }}
            style={{ display: 'flex', alignItems: 'center' }}>
            {icon}
            {title}
          </Text>
        )}
        {description && (
          <Text color='text' size='xsmall' weight={500}>
            {description}
          </Text>
        )}
      </Box>
      {buttonLabel && (
        <Button
          rounded
          variant='secondary'
          label={buttonLabel}
          style={{ width: '100%', justifyContent: 'center' }}
          onClick={onButtonClick}
        />
      )}
    </CardBody>
  </StyledCard>
);
