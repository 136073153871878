import styled from 'styled-components';

export const LogoZion = styled.img`
  width: 45px;
  margin-bottom: 32px;
`;

export const LoginContent = styled.section`
  width: 400px;
  height: 100vh;
  padding: 42px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: start;
`;
