import styled from 'styled-components';

export const StyledDaysList = styled.ul`
  display: flex;
  justify-content: flex-start;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  margin: 0 10px;
`;

export const StyledDay = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const NavButton = styled.button`
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
`;

export const StyledDayButton = styled.button`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  border-radius: 5px;
  background-color: #1f2836;
  color: #99a8c0;
  border: 1px solid #99a8c0;
  border-radius: 999px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;

  .day-number {
    font-size: 14px;
    font-weight: 800;
  }

  ${props =>
    props.selected
      ? `
      background-color: #ffffff;
      border-color: #fff;
      color: #1f2836;
    `
      : ''}

  ${props =>
    props.completed && !props.selected
      ? `
      background-color: transparent;
      border-color: #3daeab;
      color: #3daeab;
    `
      : ''}

  ${props =>
    props.late && !props.selected
      ? `
      border-color: #e74c3c;
      color: #e74c3c;
    `
      : ''}
`;
