import styled from 'styled-components';
import { Col } from '@zion-tech/ds-web';

export const LogoZion = styled.img`
  width: 45px;
  margin-right: 20px;
  margin-bottom: 5px;
`;

export const CheckinSelectorContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 24px;
`;

export const CheckinSelectorCard = styled.div`
  height: 80px;
  margin: 0px 24px;
  padding: 16px 24px;
  border-radius: 12px;
  background: #202836;
  border: 1px solid #171d27;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: start;
  gap: 16px;
`;

export const ModalContainer = styled.div`
  height: auto;
  background: #fff;
  padding: 16px 16px 8px 16px;
  margin: 0 16px 24px 16px;
  border-radius: 24px;
`;

export const B = styled.b`
  font-size: 28px;
  font-weight: 500;
`;

export const SPAN = styled.span`
  font-size: 28px;
  font-weight: normal;
`;

export const CheckInSidebar = styled(Col)`
  height: 105vh;
  align-items: center !important;
  gap: 36px;
  margin: auto;
  background: ${props => props.theme.global.colors.background[props.theme.dark ? 'dark' : 'light']};
  border-right: 1px solid
    ${props => props.theme.global.colors['background-front'][props.theme.dark ? 'dark' : 'light']};
  box-shadow: 4px 1px 20px 0px #00000090;
`;

export const H5 = styled.h5`
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 86px;
  text-align: center;
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 5px;
  text-align: center;
`;

export const STRONG = styled.strong`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
`;
