import styled from 'styled-components';

export const StyledModal = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 92%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  padding: 0px 12px;
  z-index: 999;

  ${props =>
    props.isOpen ? `opacity: 1;pointer-events: auto;` : `opacity: 0;pointer-events: none;`};

  .modal-content {
    position: relative;
    width: 80%;
    height: auto;
    padding: 8px;
    border-radius: 24px;
    background-color: #1f2836;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    transition: transform 0.3s ease-in-out;
    transform: ${props => (props.isOpen ? `scale(1)` : `scale(0.4)`)};
  }
`;

export const ModalHeader = styled.div`
  outline: none;
  align-self: flex-end;
  padding: 8px 8px 24px 24px;
`;
