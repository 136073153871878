import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Layer } from '@zion-tech/ds-web';
import Skeleton from '@components/Skeleton/Skeleton';
import { IoArrowBackOutline } from 'react-icons/io5';
import AudioPlayer from '../AudioPlayer';
import {
  Content,
  Container,
  Versicules,
  VersiculesItem,
  WrapperPlayer,
  ButtonCompleteContent,
  WrapperBible,
  ButtonClose,
  Header,
  GroupButton,
  AccessibilityFontButton,
  GroupNumberVersicules,
} from './GoalDetailModal.style';

type GoalDetailModalP = {
  onClose: () => void;
  data: { type: string; title?: string; url?: string; content?: any[] }[];
  onFinish: () => void;
};

const GoalDetailModal: React.FC<GoalDetailModalP> = ({ data, onClose, onFinish }) => {
  const [sizeFont, setSizeFont] = useState(16);
  const { t: translate, i18n } = useTranslation();
  const bibleData = data.find(mediaType => mediaType?.type === 'bible');
  const spotifyData = data.find(mediaType => mediaType?.type === 'spotify');

  const SkeletonPlanGoals = memo(() => (
    <Container>
      <Versicules style={{ marginTop: 120 }}>
        <Skeleton loading width='100%' height='68px' />
      </Versicules>
    </Container>
  ));

  SkeletonPlanGoals.displayName = '';

  if (!data) {
    return (
      <Layer modal animate animation='slide' position='center' style={{ minHeight: 600 }}>
        <SkeletonPlanGoals />;
      </Layer>
    );
  }

  const isLangPortuguese = i18n.language === 'pt-BR';

  return (
    <Layer modal animate animation='slide' position='center' style={{ minHeight: 600 }}>
      <Container>
        <Header>
          <ButtonClose type='button' onClick={() => onClose()}>
            <IoArrowBackOutline />
          </ButtonClose>
          <GroupButton>
            <AccessibilityFontButton
              secondary
              disabled={sizeFont === 16}
              label='-A'
              onClick={() => setSizeFont(prevState => prevState - 8)}
            />
            <AccessibilityFontButton
              secondary
              disabled={sizeFont === 32}
              label='+A'
              onClick={() => setSizeFont(prevState => prevState + 8)}
            />
          </GroupButton>
        </Header>

        <Content
          height={
            isLangPortuguese && spotifyData?.url
              ? Number(window.innerHeight / 1.4)
              : Number(window.innerHeight / 1.2)
          }>
          {bibleData?.content?.map(
            (
              item: { title: string; verses: { number: string[]; content: string }[] },
              index: number,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <WrapperBible key={index}>
                <Text size={`${sizeFont + 4}px`} weight='bold'>
                  {item.title?.replace('-', '')}
                </Text>
                {item?.verses.map(verse => (
                  <Versicules key={Math.random()}>
                    <VersiculesItem size={sizeFont}>
                      {verse.number.length === 1 ? (
                        <Text weight='bold'>{verse?.number[0]}</Text>
                      ) : (
                        <GroupNumberVersicules>
                          {verse.number.map(number => (
                            <Text weight='bold' key={number}>
                              {number}
                            </Text>
                          ))}
                        </GroupNumberVersicules>
                      )}
                      <Text style={{ lineHeight: 1.6 }} weight={400}>
                        {verse?.content}
                      </Text>
                    </VersiculesItem>
                  </Versicules>
                ))}
              </WrapperBible>
            ),
          )}
          <ButtonCompleteContent
            style={{ width: '100%', justifyContent: 'center' }}
            rounded
            onClick={onFinish}
            variant='primary'
            label={translate('Concluir')}
          />
        </Content>
        {spotifyData?.url && isLangPortuguese && (
          <WrapperPlayer>
            <Text size='xsmall' weight='bolder'>
              {translate('Acompanhe o plano em audio:')}
            </Text>
            <AudioPlayer title={spotifyData?.title} audioSrc={spotifyData.url} />
          </WrapperPlayer>
        )}
      </Container>
    </Layer>
  );
};

export default GoalDetailModal;
