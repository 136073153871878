import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { trackPageView } from './segment/Segment.service';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRD';
const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS;

const usePageTracking = () => {
  if (isProd) {
    const location = useLocation();
    useEffect(() => {
      if (googleAnalyticsKey && ReactGA) ReactGA.send('pageview');

      trackPageView({
        name: location.pathname,
        title: document ? document?.title : location.pathname,
        path: location.pathname,
        state: location.state,
      });
    }, [location]);
  }
};

export default usePageTracking;
