import { IGroupFilters } from '@models/Group/Group.model';

export const GroupDefaultProps: IGroupFilters = {
  filters: {
    distance: 100000,
    location: {
      lat: -23.61346,
      lng: -46.73919,
      street: 'LIBERATO CARVALHO LEITE',
      district: 'VILA SUZANA',
      cityName: 'São Paulo',
      stateName: 'São Paulo',
      countryName: 'BRASIL',
    },
  },
};
