import segment from '@segment/snippet';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRD';
const segmentKey = process.env.REACT_APP_SEGMENT;
const hasWindow = typeof window !== 'undefined';

const Segment = () => {
  if (isProd && segmentKey && hasWindow) {
    const snippet = segment.min({ host: 'cdn.segment.com', apiKey: segmentKey });

    const currScript = document.getElementById('zion-tracking');
    if (currScript) document.head.removeChild(currScript);

    const newScript = document.createElement('script');
    newScript.id = 'zion-tracking';
    newScript.innerHTML = snippet;
    document.head.appendChild(newScript);
  }
  return null;
};

export default Segment;
