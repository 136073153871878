import React, { useState } from 'react';
import { SelectProps } from 'grommet';
import { SelectField, SelectInput } from './Select.styles';

interface ISelectProps extends Partial<SelectProps> {
  name?: string;
  id?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  icon?: any;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  width?: number | string;
  options?: { label: any; value: any }[];
  onSelectOption?: any;
  multiple?: boolean;
  search?: boolean;
  valueKey?: {
    key: string;
    reduce: boolean;
  };
}

// Escaping regular expression special characters: [ \ ^ $ . | ? * + ( )
const getEscapedText = text => text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

// Create the regular expression with escaped special characters.
const formatSearchExpression = text => new RegExp(getEscapedText(text), 'i');

const Select: React.FC<ISelectProps & SelectProps> = ({
  name,
  id,
  placeholder,
  searchPlaceholder,
  icon,
  error = false,
  readOnly = false,
  disabled = false,
  width = 300,
  options = [],
  onSelectOption = undefined,
  multiple = false,
  search = false,
  valueKey = {
    key: 'value',
    reduce: true,
  },
  ...props
}) => {
  const [optionsState, setOptionsState] = useState(options);
  const [focused, setFocused] = useState(false);

  const onSearch = text => {
    const exp = formatSearchExpression(text);
    setOptionsState(options.filter(option => exp.test(option?.label)));
  };

  return (
    <SelectField htmlFor={name} error={error} direction='row' align='center'>
      {search ? (
        <SelectInput
          required
          plain
          focused={focused}
          id={id}
          name={name}
          icon={icon}
          placeholder={placeholder}
          searchPlaceholder={searchPlaceholder}
          readOnly={readOnly}
          options={optionsState}
          labelKey='label'
          valueLabel={option => {
            return option.label;
          }}
          valueKey={valueKey}
          onChange={onSelectOption}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onSearch={text => onSearch(text)}
          style={{ width: '100px' }}
          {...props}
        />
      ) : (
        <SelectInput
          required
          plain
          focused={focused}
          id={id}
          name={name}
          icon={icon}
          placeholder={placeholder}
          readOnly={readOnly}
          options={options}
          labelKey='label'
          valueLabel={option => {
            return option.label;
          }}
          valueKey={valueKey}
          onChange={onSelectOption}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={{ width: '100px' }}
          {...props}
        />
      )}
    </SelectField>
  );
};

export default Select;
