import * as React from 'react';

function GoalDetailIcon(props) {
  return (
    <svg
      width={74}
      height={53}
      viewBox='0 0 74 53'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.046.535A2 2 0 0058.684 0H35.625a2 2 0 00-.74.142l-.845.337c-.225.09-.432.219-.61.382L32.187 2a2 2 0 01-2.7 0L28.242.86a2.001 2.001 0 00-.61-.382l-.845-.337a2 2 0 00-.74-.142H2.988a2 2 0 00-1.361.535l-.988.918A2 2 0 000 2.918V42.11a2 2 0 00.639 1.465l.988.918a2 2 0 001.361.535h21.739a2 2 0 011.353.527l2.64 2.426a2 2 0 001.354.527h1.525a2 2 0 001.353-.527l2.64-2.426a2 2 0 011.354-.527h21.738a2 2 0 001.362-.535l.988-.918a2 2 0 00.639-1.465V2.918a2 2 0 00-.639-1.465l-.988-.918zM28.637 41.26a.448.448 0 01-.744.335.45.45 0 00-.116-.074l-.964-.42a2 2 0 00-.798-.166H6.405a2 2 0 01-2-2V6.093a2 2 0 012-2H24.72a2 2 0 011.362.535l2.042 1.898A2 2 0 0128.763 8l-.126 33.26zm28.63-2.325a2 2 0 01-2 2H35.626c-.253 0-.504.048-.74.142l-1.131.45a.347.347 0 00-.098.06.346.346 0 01-.573-.262V7.83a2 2 0 01.639-1.465l1.869-1.738a2 2 0 011.361-.535h18.316a2 2 0 012 2v32.842z'
        fill='#D1D8E0'
      />
      <ellipse cx={55.5} cy={35} rx={18.5} ry={18} fill='#D1D8E0' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50 29.115a1.812 1.812 0 012.824-1.503l9.317 6.275a1.945 1.945 0 010 3.227l-9.317 6.274A1.812 1.812 0 0150 41.885v-12.77z'
        fill='#263040'
      />
    </svg>
  );
}

export default GoalDetailIcon;
