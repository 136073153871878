import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Maps, ResponsiveContext } from '@zion-tech/ds-web';
import { getGroups, setGroupList, setGroupSelected } from '@models/Group/Group.state';
import { Header, Page } from '@components/index';
import { IGroup, IMarker } from '@models/Group/Group.model';
import { GroupSearchService } from '@models/Group/Group.service';
import { IResponseEnum } from '@shared/api/Api.model';
import { FloatButton, MapContainer, SidebarContainer } from '@pages/Groups/Groups.style';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import { GroupDefaultProps } from '@pages/Groups/Groups.props';
import { mountFilter, mountGroupList, mountMarkers } from '@pages/Groups/utils';
import Skeleton from '@components/Skeleton';
import { showAlert } from '@shared/alerts';
import { Filter, Location } from 'grommet-icons';

const Groups = ({ title }) => {
  const { t: translate } = useTranslation();
  const dispach = useDispatch();
  const navigate = useNavigate();
  const groups = useSelector(getGroups);
  const size = useContext(ResponsiveContext);
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false);
  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);

  /** Prevent error on first access page links */
  const addressDefault = {
    category: undefined,
    address: 'Rua Liberato Carvalho Leite, 86 - Vila Suzana, São Paulo - SP, Brazil',
    location: {
      street: 'Rua Liberato Carvalho Leite',
      district: '86, Vila Suzana',
      cityName: 'São Paulo',
      stateName: 'São Paulo',
      countryName: 'Brazil',
      lat: -23.61346,
      lng: -46.73919,
    },
    distance: 1000,
  };

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getAllGroups();
  }, []);

  useEffect(() => {
    sidebarRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  const getAllGroups = async () => {
    if (!groups?.list || groups?.list.length === 0) {
      const response = await GroupSearchService(mountFilter(addressDefault));
      if (response.status.type === IResponseEnum.success) {
        const list = mountGroupList(response.homeGroups);
        dispach(setGroupList(list));
      }
    }
  };

  const handleSelectGroup = (marker: IMarker) => {
    if (groups?.list && groups?.list.length > 0) {
      const selectedGroup = groups?.list.filter(group => group.id === marker?.id);
      if (selectedGroup[0]) dispach(setGroupSelected(selectedGroup[0]));
      navigate(`details`, { replace: false });
      trackEvent({
        type: 'info',
        name: 'ZionOnline:Links:QueroParticipar',
        value: {
          id: marker?.id,
          categoryName: marker?.categoryName,
          name: marker?.name,
          street: marker?.location?.street,
          district: marker?.location?.district,
          city: marker?.location?.city,
          state: marker?.location?.state,
          country: marker?.location?.country,
          status: marker?.statusName,
          operationDays: marker?.operationDays,
          managers: marker?.managers,
        },
      });
    } else {
      showAlert({ type: 'error', message: 'Não foi possível obter informações do LINK' });
    }
  };

  const Map = groups?.list?.length > 0 && (
    <Maps
      zoom={10}
      width='100%'
      height='91.5vh'
      streetView
      hideControls={size === 'small'}
      locations={
        groups?.list &&
        (groups?.list.map((g: IGroup) =>
          mountMarkers({
            mark: g,
            leaderLabel: translate('Líder'),
            dayLabel: translate('Dia'),
            timeLabel: translate('Horário'),
            buttonLabel: translate('Quero conhecer'),
          }),
        ) as any)
      }
      center={{
        lat: GroupDefaultProps?.filters?.location?.lat,
        lng: GroupDefaultProps?.filters?.location?.lng,
      }}
      onButtonClick={marker => handleSelectGroup(marker)}
    />
  );

  return (
    <>
      <Header />
      <Page fluid>
        <Box flex direction='row'>
          {size === 'small' && !groups.selected && (
            <FloatButton
              rounded
              variant='primary'
              icon={
                showSidebarOnMobile ? (
                  <Location size='medium' color='#FFF' />
                ) : (
                  <Filter size='medium' color='#FFF' />
                )
              }
              onClick={() => setShowSidebarOnMobile(!showSidebarOnMobile)}
            />
          )}
          <SidebarContainer
            ref={sidebarRef}
            flex
            direction='column'
            showSidebarOnMobile={showSidebarOnMobile || groups?.selected}
            selectedGroup={groups?.selected}>
            <Outlet context={{ sidebarElement: sidebarRef }} />
          </SidebarContainer>
          <MapContainer
            selectedGroup={groups?.selected}
            showSidebarOnMobile={showSidebarOnMobile || groups?.selected}
            offuscated={groups?.selected || !groups?.list || groups?.list?.length === 0}>
            {groups?.list ? Map : <Skeleton loading light width='100%' height='100%' />}
          </MapContainer>
        </Box>
      </Page>
    </>
  );
};

export default Groups;
