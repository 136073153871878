import styled from 'styled-components';
import { Text } from '@zion-tech/ds-web';
import { pixelToRem } from '@shared/styles/pxToRem';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled(Text)`
  font-size: ${pixelToRem(16)};
  margin-bottom: ${pixelToRem(4)} !important;
`;

export const AudioControls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: ${pixelToRem(10)};
  align-items: flex-end;
`;

export const PlayPauseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: ${pixelToRem(16)};
  border: 1px solid;
  padding: ${pixelToRem(8)} ${pixelToRem(4)};
  display: flex;
  align-items: center;
  border-radius: ${pixelToRem(4)};
  justify-content: center;
  cursor: pointer;
`;

export const ProgressAudio = styled.input`
  accent-color: rgba(61, 174, 171, 1);
  cursor: e-resize;
  margin: 0;
`;

export const Informations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
