import styled from 'styled-components';
import { Text, Box } from '@zion-tech/ds-web';

export const FormContainer = styled(Box)`
  min-height: 500px;
`;

export const Subtitle = styled(Text)`
  display: block;
  align-self: flex-start;
  max-width: 280px;
  font-size: 14px;
  text-align: start;
  margin-bottom: 24px;
`;
