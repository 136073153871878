import { Box } from '@zion-tech/ds-web';
import styled from 'styled-components';

export const LogoZion = styled.img`
  width: 42px;
`;

export const StyledHeader = styled.header`
  position: ${props => (props.fixed ? 'fixed' : 'relative')};
  top: 0;
  z-index: 5;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  padding: 8px 20px;
  box-sizing: border-box;
  background: #1f2836;

  .header-left,
  .header-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
  }
`;

export const CloseDrawerIcon = styled(Box)`
  position: absolute;
  padding: 24px 24px 0px 24px;
  right: 0;
`;
