import { BibleBooks } from './bible.mock';

export interface IBibleRef {
  display: string;
  prefix: string;
  book: string;
  abbr: string;
  reference: string;
  chapter: string;
  verse: string;
  verseStart: string;
  verseEnd: string;
}

export const getBibleRef = (name: string): IBibleRef => {
  const title = name.split(' ');
  const bookNumberPrefix = ['1', '2', '3', 'I', 'II', 'III'];
  let bibleFormattedName: IBibleRef = {
    prefix: '',
    display: name,
    book: name,
    abbr: '',
    reference: '',
    chapter: '',
    verse: '',
    verseStart: '',
    verseEnd: '',
  };
  if (title && title?.length > 0) {
    const hasPrefix = bookNumberPrefix.includes(title[0]);
    const prefix = hasPrefix ? title[0] : '';
    const book = hasPrefix ? title[1] : title[0];
    const abbr = hasPrefix ? BibleBooks[`${prefix}${book}`]?.abbr : BibleBooks[book]?.abbr;
    const reference = hasPrefix ? title[2] : title[1];
    const chapter = reference?.split(':')[0]?.split('-')[0] || '';
    const verse = reference?.split(':')[1] || '';
    const verseStart = verse?.split('-')[0] || '';
    const verseEnd = verse?.split('-')[1] || '';
    bibleFormattedName = {
      display: name,
      prefix,
      book,
      abbr,
      reference,
      chapter,
      verse,
      verseStart,
      verseEnd,
    };
  }
  return bibleFormattedName;
};

export const getBibleVersion = (lang: string) => {
  const version = {
    default: 'ara', // Almeida Revisada e Atualizada
    pt: 'ara', // Almeida Revisada e Atualizada
    en: 'kjv', // King James Version
    es: 'sev', // Las Sagradas Escrituras
  };
  return version[lang] || version.default;
};

export const getBibleURL = (bible: IBibleRef, lang: string) => {
  if (bible && lang) {
    const url = `https://www.bibliaonline.com.br/${getBibleVersion(lang)}/${bible?.abbr}/${
      bible?.chapter
    }/${bible?.verse}+`;
    return url;
  }
  return '';
};
