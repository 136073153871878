export const BibleBooks = {
  'Gênesis': { abbr: 'gn' },
  'Êxodo': { abbr: 'ex' },
  'Levítico': { abbr: 'lv' },
  'Números': { abbr: 'nm' },
  'Deuteronômio': { abbr: 'dt' },
  'Josué': { abbr: 'js' },
  'Juízes': { abbr: 'jz' },
  'Rute': { abbr: 'rt' },
  '1Samuel': { abbr: '1sm' },
  '2Samuel': { abbr: '2sm' },
  '1Reis': { abbr: '1rs' },
  '2Reis': { abbr: '2rs' },
  '1Crônicas': { abbr: '1cr' },
  '2Crônicas': { abbr: '2cr' },
  'Esdras': { abbr: 'ed' },
  'Neemias': { abbr: 'ne' },
  'Ester': { abbr: 'et' },
  'Jó': { abbr: 'jó' },
  'Salmos': { abbr: 'sl' },
  'Provérbios': { abbr: 'pv' },
  'Eclesiastes': { abbr: 'ec' },
  'Cantares': { abbr: 'ct' },
  'Isaías': { abbr: 'is' },
  'Jeremias': { abbr: 'jr' },
  'Lamentações': { abbr: 'lm' },
  'Ezequiel': { abbr: 'ez' },
  'Daniel': { abbr: 'dn' },
  'Oséias': { abbr: 'os' },
  'Joel': { abbr: 'jl' },
  'Amós': { abbr: 'am' },
  'Obadias': { abbr: 'ob' },
  'Jonas': { abbr: 'jn' },
  'Miquéias': { abbr: 'mq' },
  'Naum': { abbr: 'na' },
  'Habacuque': { abbr: 'hc' },
  'Sofonias': { abbr: 'sf' },
  'Ageu': { abbr: 'ag' },
  'Zacarias': { abbr: 'zc' },
  'Malaquias': { abbr: 'ml' },
  'Mateus': { abbr: 'mt' },
  'Marcos': { abbr: 'mc' },
  'Lucas': { abbr: 'lc' },
  'João': { abbr: 'jo' },
  'Atos': { abbr: 'atos' },
  'Romanos': { abbr: 'rm' },
  '1Coríntios': { abbr: '1co' },
  '2Coríntios': { abbr: '2co' },
  'Gálatas': { abbr: 'gl' },
  'Efésios': { abbr: 'ef' },
  'Filipenses': { abbr: 'fp' },
  'Colossenses': { abbr: 'cl' },
  '1Tessalonicense': { abbr: '1ts' },
  '2Tessalonicense': { abbr: '2ts' },
  '1Timóteo': { abbr: '1tm' },
  '2Timóteo': { abbr: '2tm' },
  'Tito': { abbr: 'tt' },
  'Filemom': { abbr: 'fm' },
  'Hebreus': { abbr: 'hb' },
  'Tiago': { abbr: 'tg' },
  '1Pedro': { abbr: '1pe' },
  '2Pedro': { abbr: '2pe' },
  '1João': { abbr: '1jo' },
  '2João': { abbr: '2jo' },
  '3João': { abbr: '3jo' },
  'Judas': { abbr: 'jd' },
  'Apocalipse': { abbr: 'ap' },
};
