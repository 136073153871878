import moment from 'moment';
import i18n from 'i18next';

/* Locales */
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/es';

export interface IFullDate {
  date?: Date;
  day?: number;
  month?: string;
  year?: number;
  diy?: number;
  doy?: string;
  formatted?: string;
  display?: string;
}

interface IGetFullDateProps {
  date?: Date;
  formatter?: string;
}

const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

const getDate = (date: Date | number = undefined) => {
  return date
    ? moment(date).utcOffset(new Date(date).getUTCDate()).locale(getLanguage())
    : moment().locale(getLanguage());
};

const getFullDate = ({ date, formatter }: IGetFullDateProps): IFullDate => {
  return {
    date: date || new Date(),
    day: getDate(date).date(),
    month: getDate(date).format('MMM'),
    year: getDate(date).year(),
    diy: getDate(date).dayOfYear(),
    doy: `${getDate(date).year()}-${getDate(date).dayOfYear()}`,
    formatted: getDate(date).format(formatter || 'YYYY-MM-DD'),
    display: getDate(date).format(formatter || 'dddd, Do MMMM YYYY'),
  };
};

const getFullDateNow = (): IFullDate => getFullDate({});

export { getDate, getFullDate, getFullDateNow };
