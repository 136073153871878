import styled from 'styled-components';
import { Box, Button } from '@zion-tech/ds-web';

export const SidebarContainer = styled(Box)`
  border-right: 1px solid
    ${props => props.theme.global.colors['background-front'][props.theme.dark ? 'dark' : 'light']};
  padding: 16px 24px 0 24px;
  max-height: 91.5vh;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 16px;
  transition: width 0.8s;

  @media (min-width: 100px) and (max-width: 770px) {
    width: 0%;
    display: none;
    ${props => (props.showSidebarOnMobile ? `width: 100%; display: block;` : '')}
  }
`;

export const MapContainer = styled(Box)`
  opacity: ${props => (props.offuscated ? `0.3` : `1`)};
  height: 91.5vh;
  background: #c8cdd5;
  transition: all 0.8s;
  ${props => (props.selectedGroup ? `pointer-events: none;` : '')}

  @media (min-width: 100px) and (max-width: 770px) {
    width: 100%;
    ${props => (props.showSidebarOnMobile ? `width: 0%; display: none;` : '')};
  }

  @media (min-width: 770px) and (max-width: 1024px) {
    width: 40%;
  }

  @media (min-width: 1024px) and (max-width: 1150px) {
    width: 45%;
    ${props => (props.selectedGroup ? `width: 40%;` : '')};
  }

  @media (min-width: 1150px) {
    width: 60%;
    ${props => (props.selectedGroup ? `width: 55%;` : '')};
  }
`;

export const FiltersFormContent = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
`;

export const FloatButton = styled(Button)`
  position: absolute;
  bottom: 110px;
  right: 20px;
  z-index: 2;
  background: #1f2836;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 14px;
  display: block;
  box-shadow: none;
`;

export const AuthContent = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: start;
`;
