import { IResponse, IResponseAPI } from '@shared/api/Api.model';
import ApiService from '@shared/api/Api.service';
import appConfig from '@shared/config';
import { Success, Error } from '@shared/api/status';
import { IGroupSearchResponse, IGroupFilters, IGroup, IGroupType } from './Group.model';

export interface IReponseGroupType extends IResponseAPI {
  data: {
    categories: Array<IGroupType>;
  };
}

export const GroupSearchService = async ({
  filters,
}: IGroupFilters): Promise<IResponse<IGroupSearchResponse>> => {
  try {
    const response = await ApiService<IResponseAPI<IGroupSearchResponse>>({
      method: 'POST',
      endpoint: appConfig.GROUP_ENDPOINT,
      body: { ...filters },
    });
    if (response?.status === 200 && response?.data) {
      let filteredGroups = response?.data?.homeGroups;

      // Filter only in-person Groups
      if (response?.data?.homeGroups.length > 0)
        filteredGroups = filteredGroups.filter(
          (group: IGroup) => !group.itIsOnlineMeeting && group.categorySlug !== 'links-online',
        );

      return Success(
        {
          currentLocation: response?.data.currentLocation,
          homeGroups: filteredGroups,
        },
        'Grupos encontrados',
      );
    }
    return Error('Não encontramos Grupos proximos a essa região');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const GroupInfoService = async ({ groupId }): Promise<IResponse<IGroup>> => {
  try {
    const response = await ApiService<IResponseAPI<IGroup>>({
      method: 'GET',
      endpoint: `${appConfig.GROUP_INFO_ENDPOINT}/${groupId}`,
    });
    if (response?.status === 200 && response?.data) {
      return Success({ ...response?.data }, 'Grupos encontrados');
    }
    return Error('Não encontramos informações sobre esse grupo');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const GroupTypesService = async (): Promise<IResponse<IGroupType>> => {
  try {
    const response = await ApiService<IReponseGroupType>({
      method: 'POST',
      endpoint: appConfig.GROUP_TYPES_ENDPOINT,
    });
    if (response?.status === 200 && response?.data?.categories) {
      return Success({ data: response?.data }, 'Tipos de Grupos encontrados');
    }
    return Error('Não encontramos tipos de Grupos');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const GroupEmailSendService = async (groupId: number): Promise<IResponse<any>> => {
  try {
    const response = await ApiService<IResponseAPI<any>>({
      method: 'POST',
      endpoint: `${appConfig.GROUP_BASE_ENDPOINT}/${groupId}/${appConfig.GROUP_MEMBER_INTEREST_EMAIL_ENDPOINT}`,
    });
    if (response?.status === 200 && response?.data?.processStatus === 0) {
      return Success({ data: response }, 'Email de interesse enviado para o Líder do LINK');
    }
    return Error('Não conseguimos enviar o email de interesse para o líder');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};
