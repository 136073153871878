import * as Success from './success.json';
import * as WhatsApp from './whatsapp.json';
import * as Empty from './empty.json';

const Illustrations = {
  success: Success,
  whatsapp: WhatsApp,
  empty: Empty,
};

export default Illustrations;
