import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Previous } from 'grommet-icons';
import { Box, Button, Col, Row, Text } from '@zion-tech/ds-web';
import useAuthContext from '@shared/auth/Auth.context';
import { getUser } from '@models/User/User.state';
import {
  getSelected,
  getSubgroupSelected,
  setGroupSelected,
  setSubgroup,
} from '@models/Group/Group.state';
import GroupDetails from '@pages/Groups/components/GroupDetails';
import { ContextGroupRouter, IGroup } from '@models/Group/Group.model';
import { GroupSearchService } from '@models/Group/Group.service';
import { IResponseEnum } from '@shared/api/Api.model';
import GroupsList from '../components/GroupsList';
import { mountGroupList } from '../utils';

const GroupDetailsPage = ({ title }) => {
  const { t: translate } = useTranslation();
  const dispach = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const selectedGroup = useSelector(getSelected);
  const subgroupSelected = useSelector(getSubgroupSelected);
  const context = useOutletContext<ContextGroupRouter>();
  const authContext = useAuthContext();

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    redirectIfNoSelected();
  }, []);

  useEffect(() => {
    if (!subgroupSelected) {
      getGroupsByAdress();
    }
  }, [selectedGroup]);

  window.onpopstate = () => {
    handleReturnToSearchGroups();
  };

  const getGroupsByAdress = async () => {
    const response = await GroupSearchService({
      filters: {
        distance: 5,
        location: {
          street: selectedGroup?.location?.street,
          district: selectedGroup?.location?.district,
          cityName: selectedGroup?.location?.city,
          stateName: selectedGroup?.location?.state,
          countryName: selectedGroup?.location?.country,
        },
      },
    });
    if (response.status.type === IResponseEnum.success) {
      const list = mountGroupList(
        response?.homeGroups
          .slice(0, 5)
          .filter(mark => mark.id !== selectedGroup.id)
          .slice(0, 4),
      );
      dispach(setSubgroup(list));
    }
  };

  const redirectIfNoSelected = () => {
    if (!selectedGroup || selectedGroup === null) {
      handleReturnToSearchGroups();
    }
  };

  const handleReturnToSearchGroups = () => {
    dispach(setGroupSelected(undefined));
    navigate(`/links`, { replace: false });
  };

  const handleJoinGroup = () => {
    if (authContext.isUserAuthenticated()) handleUserAuthenticated();
    else navigate(`/links/auth`, { replace: false });
  };

  const handleUserAuthenticated = () => {
    if (selectedGroup && user) {
      navigate(`/links/contact`, { replace: false });
    } else {
      handleReturnToSearchGroups();
    }
  };

  const handleSelectGroup = (group: IGroup) => {
    context?.sidebarElement?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    dispach(setGroupSelected(group));
  };

  return selectedGroup ? (
    <>
      <Button
        icon={<Previous color='white!' size='small' />}
        label={translate('Voltar para a busca de LINKs')}
        style={{ width: `fit-content` }}
        margin={{ bottom: `32px` }}
        onClick={() => handleReturnToSearchGroups()}
      />
      <GroupDetails key='details' onButtonClick={() => handleJoinGroup()} />

      <Box pad={{ horizontal: '5%' }}>
        <Row>
          <Col style={{ margin: `20px 0` }}>
            <hr style={{ opacity: 0.2 }} />
          </Col>
        </Row>

        {selectedGroup?.categoryName !== 'LINKS - ONLINE' ? (
          <Row>
            <Col>
              <GroupsList
                locations={subgroupSelected}
                onButtonClick={group => handleSelectGroup(group)}
                title={translate('Veja outros links nessa região')}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}

        <Row>
          <Col style={{ marginTop: 40, marginBottom: 20 }}>
            <Text size='medium' style={{ paddingTop: `4px` }}>
              {translate('Não encontrou nenhum LINK que funciona pra você')}{' '}
              <a
                style={{ color: '#3daeab' }}
                href='https://docs.google.com/forms/d/e/1FAIpQLSeeoIDmVXoSG6Mn0DnppLGZF07yQclbsb-21dbo9zwrDDJW8A/viewform'>
                {translate('Clique aqui.')}
              </a>
            </Text>
          </Col>
        </Row>
      </Box>
    </>
  ) : null;
};

export default GroupDetailsPage;
