export const getName = name => {
  if (name) {
    const nameSplitted: Array<string> = name.toString().split(' ');
    return {
      first: nameSplitted[0].toLocaleLowerCase() || '',
      last: nameSplitted[nameSplitted.length - 1].toLocaleLowerCase() || '',
      all: name,
    };
  }
  return {};
};

export const capitalizeWord = name => {
  return name
    .split(' ')
    .map(word => {
      const newWord = word.toLowerCase();
      return newWord.charAt(0).toUpperCase() + newWord.slice(1);
    })
    .join(' ');
};

export const removeBar = (string: string) => {
  return string.replace(/\//, '');
};

export const firstLetterUpper = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
