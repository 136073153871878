import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { IResponse, IResponseEnum } from '@shared/api/Api.model';
import appConfig from '@shared/config';
import { AppAccessService } from '../Auth.service';
import useAuthContext from '../Auth.context';

export interface IRouteGuardProps {
  protectedRoute?: boolean;
  children: JSX.Element;
}

export const RouteGuard: React.FC<IRouteGuardProps> = ({ protectedRoute = false, children }) => {
  const location = useLocation();
  const authContext = useAuthContext();

  const getAuthApp = async () => {
    if (authContext.isApplicationAuthenticated()) return true;
    const response: IResponse = await AppAccessService({ apiKey: appConfig.APP_KEY });
    if (response.status.type === IResponseEnum.success) return true;
    return false;
  };

  if (protectedRoute && !authContext.isUserAuthenticated())
    return <Navigate to='/login' state={{ from: location }} replace />;

  if (!protectedRoute && !getAuthApp())
    return <Navigate to='/404' state={{ from: location }} replace />;

  return children;
};

export default RouteGuard;
