import React, { useState } from 'react';
import styled from 'styled-components';

export const StyledCheck = styled.div`
  label {
    display: inline-block;
    color: #fff;
    cursor: pointer;
    position: relative;
    font-size: 16px;

    span {
      display: inline-block;
      position: relative;
      background-color: transparent;
      width: 25px;
      height: 25px;
      transform-origin: center;
      border: 2px solid #fff;
      border-radius: 50%;
      vertical-align: -6px;
      margin-right: 14px;
      transition: all 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);

      &:before {
        content: '';
        width: 0px;
        height: 3px;
        border-radius: 10px;
        background: #fff;
        position: absolute;
        transform: rotate(45deg);
        top: 10px;
        left: 6px;
        transition: width 50ms ease 50ms;
        transform-origin: 0% 0%;
      }

      &:after {
        content: '';
        width: 0;
        height: 3px;
        border-radius: 10px;
        background: #fff;
        position: absolute;
        transform: rotate(305deg);
        top: 15px;
        left: 7px;
        transition: width 50ms ease;
        transform-origin: 0% 0%;
      }
    }
  }
  input[type='checkbox'] {
    display: none;
    &:checked {
      + label {
        span {
          background-color: #3daead;
          transform: scale(1.25);
          border: 2px solid transparent;

          &:after {
            width: 12px;
            background: #fff;
            transition: width 150ms ease 100ms;
          }

          &:before {
            width: 7px;
            background: #fff;
            transition: width 150ms ease 100ms;
          }
        }
      }
    }
  }
`;

export interface ICheckProps {
  id: string;
  name: string;
  label?: string;
  checked?: boolean;
  onCheck: any;
}

const Check: React.FC<ICheckProps> = ({ id, name, label, checked = false, onCheck }) => {
  const [isChecked, setChecked] = useState(checked);
  const handleCheck = () => {
    const value = !isChecked;
    setChecked(value);
    onCheck(value);
  };
  return (
    <StyledCheck onClick={() => handleCheck()} tabIndex={0}>
      <input type='checkbox' id={id} name={name} checked={isChecked} readOnly />
      <label htmlFor='check'>
        <span />
        {label}
      </label>
    </StyledCheck>
  );
};

export default React.memo(Check);
