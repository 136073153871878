import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import { Box } from '@zion-tech/ds-web';
import { IPlanGoal } from '@models/Plan/Plan.model';
import { Week } from './types';
import CalendarDays from './CalendarDays';

export interface ICalendarProps {
  goals?: IPlanGoal[];
  initialDate?: Moment;
  onSelect?: any;
}

const Calendar: React.FC<ICalendarProps> = ({ goals, initialDate, onSelect }) => {
  const [currentDate, setCurrentDate] = useState<Moment>(initialDate);
  const [weekDays, setWeekDays] = useState<Week | null>();

  useEffect(() => {
    setCalendarDays();
  }, [currentDate]);

  const setCalendarDays = () => {
    const weekData: Week = [];
    for (let index = 0; index < 7; index += 1) {
      weekData.push(currentDate.clone().add(index, 'day'));
    }
    setWeekDays(weekData);
  };

  const handleCalendarChange = (date: Moment) => {
    setCurrentDate(date);
    setCalendarDays();
  };

  return (
    currentDate && (
      <Box flex>
        <CalendarDays
          goals={goals}
          currentDate={currentDate}
          weekDays={weekDays}
          onChange={date => handleCalendarChange(date)}
          onSelect={goal => onSelect(goal)}
        />
      </Box>
    )
  );
};

export default React.memo(Calendar);
