import React, { useEffect } from 'react';
import { Box, Button, Layer } from '@zion-tech/ds-web';
import { privacyPoliceService } from '@shared/auth/Auth.service';
import Skeleton from '@components/Skeleton';

export const PrivacyPolicyModal: React.FC<any> = ({ open, onClose }) => {
  const getPrivacyPolicy = async () => {
    const response = await privacyPoliceService();
    if (response) {
      const el = document.getElementById('privacy-policy-content');
      if (el) el.innerHTML = response?.Privacy;
    }
  };

  useEffect(() => {
    if (open) getPrivacyPolicy();
  }, [open]);

  return (
    open && (
      <Layer
        modal
        animate
        animation='slide'
        position='center'
        onClickOutside={onClose}
        onEsc={onClose}>
        <Box pad='24px' height='300px' style={{ minWidth: 600, overflow: 'scroll' }}>
          <div id='privacy-policy-content'>
            <Skeleton loading width='100%' height='42px' />
            <Skeleton loading width='80%' height='22px' style={{ marginTop: 24 }} />
            <Skeleton loading width='80%' height='22px' style={{ marginTop: 8 }} />
            <Skeleton loading width='40%' height='22px' style={{ marginTop: 8 }} />
            <Skeleton loading width='60%' height='22px' style={{ marginTop: 8 }} />
            <Skeleton loading width='80%' height='22px' style={{ marginTop: 8 }} />
          </div>
        </Box>
        <Box pad={{ horizontal: '24px', bottom: '24px', top: '10px' }} width='100%'>
          <Button
            rounded
            variant='primary'
            label='Entendi'
            style={{ width: 'fit-content' }}
            onClick={onClose}
          />
        </Box>
      </Layer>
    )
  );
};
