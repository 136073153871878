import ApiService from '@shared/api/Api.service';
import { IResponse, IResponseAPI } from '@shared/api/Api.model';
import { Success, Error } from '@shared/api/status';
import { getFullDate } from '@shared/date';
import appConfig from '@shared/config';
import { IPlan, IPlanGoal } from './Plan.model';

export const PlansService = async (): Promise<IResponse> => {
  try {
    const response = await ApiService<IResponseAPI>({
      method: 'GET',
      endpoint: appConfig.PLANS_BASE_ENDPOINT,
    });
    if (response?.status === 200 && response?.data) {
      return Success({ ...response?.data }, 'Planos encontrados');
    }
    return Error('Não encontramos planos disponíveis.');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const PlanService = async (planSlug: string): Promise<IResponse> => {
  try {
    const response = await ApiService<IResponseAPI>({
      method: 'GET',
      endpoint: appConfig.PLANS_GOALS_ENDPOINT(planSlug),
    });
    if (response?.status === 200 && response?.data) {
      let plan: IPlan = response?.data;
      if (plan && plan.strategy === 'date') {
        plan = {
          ...plan,
          goals: response?.data?.goals?.map((goal: IPlanGoal) => ({
            ...goal,
            fullDate: getFullDate({ date: goal.date }),
          })),
        };
      }
      return Success(plan, 'Metas de plano encontrados');
    }
    return Error('Não encontramos metas disponíveis para esse plano.');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const PlanSubscribeService = async (planSlug: string): Promise<IResponse> => {
  try {
    const response = await ApiService<IResponseAPI>({
      method: 'POST',
      endpoint: appConfig.PLANS_SUBSCRIBE_ENDPOINT(planSlug),
      body: {},
    });
    if (response?.status === 200 && response?.data?.processStatus === 0) {
      return Success({ data: response }, 'Enviado a solicitaçáo de engajamento no plano');
    }
    return Error('Não conseguimos enviar a solicitação de engajamento neste plano');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const PlanGoalFollowup = async (
  goalItemId: string,
  completed: boolean,
): Promise<IResponse> => {
  try {
    const response = await ApiService<IResponseAPI>({
      method: 'POST',
      endpoint: appConfig.PLANS_GOALS_ITEM_FOLLOWUP_ENDPOINT(goalItemId),
      body: { completed },
    });
    if (response?.status === 200 && response?.data?.processStatus === 0) {
      return Success(
        { data: response?.data },
        'Foi marcado um followup neste item de meta do plano',
      );
    }
    return Error('Não conseguimos enviar o followup para este item da meta do plano');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};

export const PlanGoalContent = async (goalItemId: string, language: string): Promise<IResponse> => {
  try {
    const response = await ApiService<IResponseAPI>({
      method: 'GET',
      config: {
        headers: { lang: language },
      },
      endpoint: appConfig.PLANS_GOAL_ITEM_CONTENT_ENDPOINT(goalItemId),
    });

    if (response.status === 200 && response.data?.processStatus === 0) {
      return Success(
        { data: response.data.contents?.[0].medias },
        'Conteúdo do Objetivo encontrado',
      );
    }

    return Error('Não conseguimos encontrar conteúdo desse objetivo');
  } catch (error) {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};
