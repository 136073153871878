import { configureStore } from '@reduxjs/toolkit';
import App from '@models/App/App.state';
import User from '@models/User/User.state';
import Group from '@models/Group/Group.state';
import Plan from '@models/Plan/Plan.state';

const Store = configureStore({
  reducer: {
    app: App,
    user: User,
    groups: Group,
    plans: Plan,
  },
});

export default Store;
