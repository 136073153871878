/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import { IGroup, IGroupType } from './Group.model';

const STATE_GROUPS = {
  data: {
    selected: undefined as IGroup,
    list: undefined as IGroup[],
    types: [] as IGroupType[],
  },
};

/**
 * Reducer
 */
export const GroupState = createSlice({
  name: 'groups',
  initialState: STATE_GROUPS,
  reducers: {
    setGroupSelected: (state, action) => {
      state.data.selected = action.payload;
    },
    setSubgroup: (state, action) => {
      state.data.selected.subGroups = action.payload;
    },
    setGroupList: (state, action) => {
      state.data.list = action.payload;
    },
    setGroupTypes: (state, action) => {
      state.data.types = action.payload;
    },
  },
});

/**
 * Selectors
 */
export const get = state => state.groups.data;

export const getGroups = state => state.groups.data;

export const getSelected = state => state.groups.data?.selected as IGroup;

export const getSubgroupSelected = state => state.groups.data?.selected?.subGroups as IGroup[];

export const { setGroupSelected, setSubgroup, setGroupList, setGroupTypes } = GroupState.actions;

export default GroupState.reducer;
