import ApiService from '@shared/api/Api.service';
import { IResponse, IResponseAPI } from '@shared/api/Api.model';
import { Success, Error } from '@shared/api/status';
import appConfig from '@shared/config';
import useAuthContext from '@shared/auth/Auth.context';

export const CheckInService = async ({ eventData }): Promise<IResponse> => {
  try {
    const authContext = useAuthContext();

    const response = await ApiService<IResponseAPI>({
      method: 'POST',
      endpoint: appConfig.CHECK_IN_ENDPOINT,
      body: {
        eventData,
      },
      config: {
        headers: {
          'x-date': new Date().toUTCString(),
        },
      },
      token: authContext.getToken(),
    });
    if (response?.status === 200 && response?.data?.processStatus === 0) {
      return Success({ data: response }, 'Check-in realizado');
    }
    return Error('O check-in não pode ser realizado no momento.');
  } catch {
    return Error('Um erro interno aconteceu, por gentileza informe a equipe da Zion Tecnologia.');
  }
};
