import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en/translation.json';
import ptBR from './pt-br/translation.json';
import pt from './pt/translation.json';
import es from './es/translation.json';

// https://translate.i18next.com/

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      'pt-BR': {
        translation: ptBR,
      },
      'pt': {
        translation: pt,
      },
      'en': {
        translation: en,
      },
      'es': {
        translation: es,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
