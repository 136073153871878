/* INTERFACES */

export interface IGroup {
  id?: string | number;
  name?: string;
  categoryName?: string;
  categorySlug?: string;
  isRecruiting?: boolean;
  statusName?: string;
  statusSlug?: string;
  itIsOnlineMeeting?: false;
  totalMembers?: number;
  managers?: Array<ILeader>;
  operationDays?: Array<IOperationDays>;
  subGroups?: Array<IGroup>;
  contact?: {
    phone?: string;
    email?: string;
  };
  location?: {
    lat?: number | string;
    lng?: number | string;
    city?: string;
    street?: string;
    country?: string;
    district?: string;
    state?: string;
  };
}

export interface IGroupFilters {
  filters: {
    dayId?: number;
    distance?: number;
    location?: {
      lat?: string | number;
      lng?: string | number;
      street?: string;
      district?: string;
      cityName?: string;
      stateName?: string;
      countryName?: string;
    };
    category?: {
      id?: number;
      name?: string;
    };
    itIsOnlineMeeting?: boolean;
  };
}

export interface IGroupSearchResponse {
  currentLocation: {
    lat: number;
    lng: number;
  };
  homeGroups: Array<IGroup>;
}

export interface ILeader {
  id: number | string;
  name: string;
  role?: RolesEnum;
  photoUrl?: string;
}

export interface IOperationDays {
  dayId?: number;
  startAt?: string;
  endAt?: string;
}

export interface IGroupType {
  id: string;
  name: string;
  slug?: string;
}

export interface IAtendee {
  id: number | string;
  name: string;
  photoUrl?: string;
  role?: RolesEnum;
  idRoleGroup?: number;
}

export interface IMarker extends IGroup {
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  buttonLabel?: string;
  content?: any;
  lat?: any;
  lng?: any;
}

/* ENUM */

export enum WeekdayEnum {
  'Segunda' = 2,
  'Terça' = 3,
  'Quarta' = 4,
  'Quinta' = 5,
  'Sexta' = 6,
  'Sábado' = 0,
  'Domingo' = 1,
}

export enum RolesEnum {
  lider1 = 'Líder 1',
  lider2 = 'Líder 2',
  coordenador1 = 'Coordenador 1',
  coordenador2 = 'Coordenador 2',
  CoordenadorGeral1 = 'Coord. Geral 1',
  CoordenadorGeral2 = 'Coord. Geral 2',
  CoordenadorGeral3 = 'Coord. Geral 3',
  participantePresencial = 'LINK: PARTICIPANTE PRESENCIAL',
  participanteOnline = 'LINK: PARTICIPANTE ONLINE',
}

export enum GroupStatusEnum {
  'RECEBENDO PARTICIPANTES' = 'Recebendo novos participantes',
  'LOTADO' = 'Infelizmente este LINK está lotado',
}

export interface ContextGroupRouter {
  sidebarElement: React.MutableRefObject<HTMLDivElement>;
}
