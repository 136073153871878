import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@zion-tech/ds-web';
import StepProgressBar from '@components/ProgressBar';
import { FormContainer } from './SignUpForm.style';
import StepOne from './SignUpFormStepOne';
import StepTwo from './SignUpFormStepTwo';
import StepThree from './SignUpFormStepThree';

const SignUpForm = ({
  showCloseButton = false,
  showTitle = false,
  onSuccess = undefined,
  onError = undefined,
  onCloseButtonClick = undefined,
}) => {
  const { t: translate } = useTranslation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    newBirth: '',
    ddiCellphone: '',
    cellphone: '',
    gender: '',
    areBaptized: '',
    areChristian: '',
    password: '',
    passwordConfirm: '',
    privacyPolice: true,
    socialMedia: '',
    howMeet: '',
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleInputData = input => e => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [input]: value,
    }));
  };

  const onSuggestionSelect = input => event => {
    setFormData(prevState => ({
      ...prevState,
      [input]: event.suggestion,
    }));
  };

  switch (step) {
    case 1:
      return (
        <FormContainer flex direction='column' align='center'>
          {showTitle && (
            <Text size='large' style={{ paddingBottom: 32, textAlign: 'left' }}>
              {translate('Que bom te conhecer!')}
            </Text>
          )}

          <StepProgressBar currentStep={step} />

          <StepOne nextStep={nextStep} handleFormData={handleInputData} values={formData} />
          {showCloseButton && (
            <Button
              rounded
              variant='ghost'
              label={translate('Voltar para o Login')}
              style={{ width: '100%', marginTop: 16, justifyContent: 'center' }}
              onClick={onCloseButtonClick}
            />
          )}
        </FormContainer>
      );
    case 2:
      return (
        <FormContainer flex direction='column' align='center'>
          {showTitle && (
            <Text size='large' style={{ paddingBottom: 32, textAlign: 'left' }}>
              {translate('Que bom te conhecer!')}
            </Text>
          )}
          <StepProgressBar currentStep={step} />
          <StepTwo
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            suggestionSelect={onSuggestionSelect}
            values={formData}
          />
          {showCloseButton && (
            <Button
              rounded
              variant='ghost'
              label={translate('Voltar para o Login')}
              style={{ width: '100%', marginTop: 16, justifyContent: 'center' }}
              onClick={onCloseButtonClick}
            />
          )}
        </FormContainer>
      );
    case 3:
      return (
        <FormContainer flex direction='column' align='center'>
          {showTitle && (
            <Text size='large' style={{ paddingBottom: 32, textAlign: 'left' }}>
              {translate('Que bom te conhecer!')}
            </Text>
          )}
          <StepProgressBar currentStep={step} />
          <StepThree
            onError={onError}
            onSuccess={onSuccess}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
          {showCloseButton && (
            <Button
              rounded
              variant='ghost'
              label={translate('Voltar para o Login')}
              style={{ width: '100%', marginTop: 16, justifyContent: 'center' }}
              onClick={onCloseButtonClick}
            />
          )}
        </FormContainer>
      );
    default:
      return <div />;
  }
};

export default SignUpForm;
