import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Button, Card, CardBody, CardFooter, Text } from '@zion-tech/ds-web';
import { IGroup } from '@models/Group/Group.model';
import { CicloTag } from '@pages/Groups/components/CicloTag';

export const StyledCard = styled(Card)`
  height: 290px;
  border-radius: 12px;
  padding-bottom: 0px;

  @media (min-width: 100px) and (max-width: 770px) {
    height: 260px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-top: 0px;

  @media (min-width: 100px) and (max-width: 770px) {
    margin-top: 0px;
  }
`;

export interface IGroupDetailsProps {
  group: IGroup;
  status: string;
  title: string;
  subtitle: string;
  children?: ReactNode;
  onButtonClick?: any;
}

const CardGroup: React.FC<IGroupDetailsProps> = ({
  group,
  status,
  title,
  subtitle,
  children,
  onButtonClick,
}) => {
  const { t: translate } = useTranslation();
  return (
    <StyledCard background={status === 'LOTADO' ? `background-darker` : 'background-contrast'}>
      <CardBody
        gap='small'
        pad={{ horizontal: '30px', vertical: '30px' }}
        style={{ minHeight: 'auto', paddingBottom: 10 }}>
        <Box flex direction='column' style={{ flex: 2, gap: 18 }}>
          {status === 'LOTADO' && <CicloTag literal ciclo={status} />}
          <Box flex direction='column' gap='4px'>
            {title && (
              <Text
                color='text-strong'
                size='16px'
                weight='bolder'
                style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
                {translate(title)}
              </Text>
            )}
            {subtitle && (
              <Text
                color='text'
                size='12px'
                weight={600}
                style={{ display: 'flex', alignItems: 'center' }}>
                {translate(subtitle)}
              </Text>
            )}
          </Box>
        </Box>
        <Box gap='8px' pad={{ bottom: `12px` }} style={{ flex: 1 }}>
          {children}
        </Box>
      </CardBody>
      <CardFooter style={{ paddingTop: 0 }}>
        <Box flex pad={{ bottom: `12px` }}>
          <StyledButton
            rounded
            variant='secondary'
            label={translate('Quero conhecer')}
            onClick={() => onButtonClick(group)}
          />
        </Box>
      </CardFooter>
    </StyledCard>
  );
};

export default CardGroup;
