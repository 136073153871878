import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { Button, Text } from '@zion-tech/ds-web';
import Modal from '@components/Modal';
import StatusMessage from '@components/StatusMessage';
import { getUser } from '@models/User/User.state';
import { useSelector } from 'react-redux';
import { capitalizeWord } from '@shared/naming';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 0;
  gap: 12px;
`;

const FullPlanCompletedModal = ({ isOpen, onClose, ...props }) => {
  const { t: translate } = useTranslation();
  const { width, height } = useWindowSize();
  const user = useSelector(getUser);

  return (
    <>
      <Modal isOpen={isOpen} showCloseIcon={false} handleClose={onClose} {...props}>
        <StatusMessage
          key={1}
          illustration='success'
          style={{ position: 'absolute', top: 0, display: 'flex', height: 'auto', marginTop: -60 }}
        />
        <Text
          size='large'
          weight={700}
          style={{
            textAlign: 'center',
            padding: `40px 32px 0px 32px`,
            textTransform: 'capitalize',
            marginBottom: `30px`,
          }}>
          {translate('Parabéns')}, {capitalizeWord(user.data.name?.split(' ')[0])}
        </Text>
        <Text
          size='small'
          weight={700}
          style={{ textAlign: 'center', padding: `12px 32px 0px 32px`, marginBottom: `30px` }}>
          {translate(
            'Você completou a leitura do plano bíblico em um ano e estamos feliz pela sua jornada.',
          )}
        </Text>
        <Text
          size='small'
          weight={700}
          style={{ textAlign: 'center', padding: `12px 32px 0px 32px`, marginBottom: `30px` }}>
          {translate('Que Deus te abençoe e te esperamos ano que vem!')}
        </Text>
        <Text weight={100}>{translate('João 8.31-32')}</Text>
        <Content>
          <Button
            rounded
            variant='primary'
            label={translate('Voltar')}
            style={{ width: '100%', justifyContent: 'center' }}
            onClick={() => onClose(false)}
          />
        </Content>
      </Modal>
      {isOpen && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={50}
          tweenDuration={50}
          style={{ zIndex: 99999 }}
        />
      )}
    </>
  );
};

export default FullPlanCompletedModal;
