import React from 'react';
import { Box, Avatar } from '@zion-tech/ds-web';
import { FaUserCircle } from 'react-icons/fa';

export interface AuthGuardProps {
  loading: boolean;
  photo: string;
  onProfileClick: any;
}

export const ProfileAvatar: React.FC<AuthGuardProps> = ({ loading, photo, onProfileClick }) =>
  !loading ? (
    <Box flex direction='row' align='center' justify='center' gap='8px'>
      {photo ? (
        <Avatar
          src={photo}
          style={{ height: 42, width: 42, boxShadow: `rgb(13 18 25 / 69%) 0px 2px 6px 0px` }}
          onClick={onProfileClick}
        />
      ) : (
        <FaUserCircle size={32} color='#FFFFFF' onClick={onProfileClick} />
      )}
    </Box>
  ) : null;
