import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import QrScan from 'react-qr-reader';
import { Text } from '@zion-tech/ds-web';
import { showAlert } from '@shared/alerts';
import { getUser } from '@models/User/User.state';
import { Page, Header, Modal } from '@components/index';
import QRCodeIcon from './components/Icons/QRCode';
import UserCircleIcon from './components/Icons/UserCircle';
import { CheckinSelectorCard, CheckinSelectorContainer, ModalContainer } from './CheckIn.style';

const CheckinTypes = ({ title }) => {
  const { t: translate } = useTranslation();
  const user = useSelector(getUser);
  const [openScanQRCode, setOpenScanQRCode] = useState(false);
  const [openShowQRCode, setOpenShowQRCode] = useState(false);

  useEffect(() => {
    document.title = title;
  }, []);

  const handleScanQRCode = data => {
    if (data) {
      window.location.href = data;
    }
  };

  return (
    <>
      <Header fixed />
      <Page fluid>
        <CheckinSelectorContainer>
          <Text size='small' weight={800} style={{ paddingLeft: 24 }}>
            {translate('Selecione uma forma de Checkin')}:
          </Text>
          <CheckinSelectorCard onClick={() => setOpenScanQRCode(!openScanQRCode)}>
            <QRCodeIcon />
            <Text size='xsmall' weight={600}>
              {translate('Escanear o QRCode do evento')}
            </Text>
          </CheckinSelectorCard>
          <CheckinSelectorCard onClick={() => setOpenShowQRCode(!openShowQRCode)}>
            <UserCircleIcon />
            <Text size='xsmall' weight={600}>
              {translate('Mostrar o meu QRCode')}
            </Text>
          </CheckinSelectorCard>
        </CheckinSelectorContainer>
      </Page>

      <Modal isOpen={openScanQRCode} handleClose={() => setOpenScanQRCode(false)}>
        {openScanQRCode && (
          <QrScan
            delay={300}
            onError={err => showAlert({ type: 'error', message: err })}
            onScan={val => handleScanQRCode(val)}
            style={{ height: 240, width: 240, marginBottom: 32, border: '1px solid #fff' }}
          />
        )}
      </Modal>

      <Modal isOpen={openShowQRCode} handleClose={() => setOpenShowQRCode(false)}>
        <ModalContainer>
          {openShowQRCode && (
            <QRCode
              id='qrcode'
              value={JSON.stringify({ C: user?.data?.externalUserId, N: user?.data?.name })}
              size={180}
            />
          )}
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CheckinTypes;
