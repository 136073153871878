import React, { useState } from 'react';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormView, Hide } from 'grommet-icons';
import { Button, Form, FormControl, Box, TextInput, CheckBox, Anchor } from '@zion-tech/ds-web';
import { trackEvent } from '@shared/tracking/segment/Segment.service';
import { removeBar, firstLetterUpper } from '@shared/naming';
import { PasswordField, PasswordInput } from './SignUpForm.style';
import { SignUpService } from '../Auth.service';
import { PrivacyPolicyModal } from './components/PrivacyPolicyModal';

const StepThree = ({
  onSuccess = undefined,
  onError = undefined,
  prevStep,
  handleFormData,
  values,
}) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputPasswordFocused, setinputPasswordFocused] = useState(false);
  const [privacyPoliceChecked, setPrivacyPoliceChecked] = useState(true);
  const [privacyPoliceModalOpen, setPrivacyPoliceModalOpen] = useState(false);
  const [status, setStatus] = useState({
    type: '',
    message: '',
  });

  const handleLogin = async value => {
    setLoading(true);

    const response = await SignUpService({
      ...value,
      hasNewBirth: value.hasNewBirth === translate('Sim'),
      isBaptized: value.isBaptized === translate('Sim'),
    });
    if (response.status.type === 'success') {
      onSuccess(response.status.message);
      setLoading(false);
    } else {
      onError(response.status);
      setLoading(false);
    }
  };

  const submitFormData = async e => {
    e.preventDefault();
    if (!(await validate())) return;
    trackEvent({
      type: 'info',
      name: `ZionOnline:${firstLetterUpper(removeBar(location.pathname))}:Cadastro:Passo3`,
      value: values,
    });
    handleLogin(values);
  };

  const validate = async () => {
    const schema = yup.object().shape({
      passwordConfirm: yup
        .string()
        .required('Confirme sua senha por favor')
        .oneOf([yup.ref('password')], 'As senhas não são iguais'),
      password: yup
        .string()
        .required('A senha é necessária')
        .min(4, 'Sua senha deve conter pelo menos 4 caracteres'),
      email: yup.string().email('E-mail inválido').required('Por favor informe um e-mail válido'),
    });
    try {
      await schema.validate(values);
      return true;
    } catch (err) {
      setStatus({
        type: err.path,
        message: err.errors,
      });
      return false;
    }
  };

  return (
    <>
      <Form onSubmit={submitFormData} style={{ width: 310 }}>
        <FormControl
          label={translate('E-mail')}
          style={{ marginBottom: 16 }}
          htmlFor='email'
          error={status.type === 'email'}
          message={status.type === 'email' ? translate(status.message) : null}>
          <TextInput
            type='email'
            name='email'
            id='email'
            defaultValue={values.email}
            onChange={handleFormData('email')}
            style={{ width: '100%' }}
          />
        </FormControl>
        <FormControl
          label={translate('Senha')}
          style={{ marginBottom: 16 }}
          error={status.type === 'password'}
          message={status.type === 'password' ? translate(status.message) : null}>
          <PasswordField
            htmlFor='password'
            focused={inputPasswordFocused}
            direction='row'
            align='center'>
            <PasswordInput
              plain
              name='password'
              id='password'
              readOnly={loading}
              type={showPassword ? 'text' : 'password'}
              defaultValue={values.password}
              onFocus={() => setinputPasswordFocused(true)}
              onBlur={() => setinputPasswordFocused(false)}
              onChange={handleFormData('password')}
              style={{ width: '100%' }}
            />
            {showPassword ? (
              <Hide onClick={() => setShowPassword(!showPassword)} style={{ marginRight: 8 }} />
            ) : (
              <FormView onClick={() => setShowPassword(!showPassword)} style={{ marginRight: 8 }} />
            )}
          </PasswordField>
        </FormControl>
        <FormControl
          label={translate('Confirmar Senha')}
          style={{ marginBottom: 16 }}
          error={status.type === 'passwordConfirm'}
          message={status.type === 'passwordConfirm' ? translate(status.message) : null}>
          <PasswordField
            htmlFor='passwordConfirm'
            focused={inputPasswordFocused}
            direction='row'
            align='center'>
            <PasswordInput
              plain
              name='passwordConfirm'
              id='passwordConfirm'
              readOnly={loading}
              type={showPassword ? 'text' : 'password'}
              defaultValue={values.passwordConfirm}
              onFocus={() => setinputPasswordFocused(true)}
              onBlur={() => setinputPasswordFocused(false)}
              onChange={handleFormData('passwordConfirm')}
              style={{ width: '100%' }}
            />
            {showPassword ? (
              <Hide onClick={() => setShowPassword(!showPassword)} style={{ marginRight: 8 }} />
            ) : (
              <FormView onClick={() => setShowPassword(!showPassword)} style={{ marginRight: 8 }} />
            )}
          </PasswordField>
        </FormControl>
        <FormControl style={{ marginBottom: 36, fontSize: 14 }} htmlFor='privacyPolice'>
          <CheckBox
            label={
              <Anchor onClick={() => setPrivacyPoliceModalOpen(true)}>
                {translate('Concordo com a Politica de Privacidade')}
              </Anchor>
            }
            style={{ width: '100%' }}
            name='privacyPolice'
            checked={privacyPoliceChecked}
            onChange={() => {
              handleFormData('privacyPolice');
              setPrivacyPoliceChecked(!privacyPoliceChecked);
            }}
          />
        </FormControl>
        <Box style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-around' }}>
          <Button
            rounded
            type='submit'
            variant='secondary'
            label={translate('Voltar')}
            onClick={prevStep}
            style={{ width: '40%', justifyContent: 'center' }}
          />
          <Button
            rounded
            loading={loading}
            disabled={!privacyPoliceChecked}
            type='submit'
            variant='primary'
            label={translate('Cadastrar')}
            style={{ width: '55%', justifyContent: 'center' }}
          />
        </Box>
      </Form>
      <PrivacyPolicyModal
        open={privacyPoliceModalOpen}
        onClose={() => setPrivacyPoliceModalOpen(!privacyPoliceModalOpen)}
      />
    </>
  );
};

export default StepThree;
