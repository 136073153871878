import React from 'react';
import { StyledContainer } from './Page.style';

export interface IPageProps {
  children: JSX.Element;
  fluid?: boolean;
  fixedHeader?: boolean;
  fullHeight?: boolean;
  bgGradient?: boolean;
  style?: any;
  props?: any;
}

const Page: React.FC<IPageProps> = ({
  children,
  fluid = false,
  fixedHeader = false,
  fullHeight = false,
  bgGradient = false,
  style = {},
  ...props
}) => (
  <StyledContainer
    fluid={fluid}
    fixedHeader={fixedHeader}
    fullheight={fullHeight}
    bgGradient={bgGradient}
    style={style}
    {...props}>
    {children}
  </StyledContainer>
);

export default Page;
