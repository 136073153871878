import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormControl, PlacesAutocomplete, Button } from '@zion-tech/ds-web';
import { Location, Search } from 'grommet-icons';
import { IGroupFilters, IGroupType, WeekdayEnum } from '@models/Group/Group.model';
import { mountAddressFilter } from '@pages/Groups/utils';
import { showAlert } from '@shared/alerts';
import Select from '@components/Select';

export interface IFiltersFormProps {
  groupTypes: IGroupType[];
  submitForm?: any;
}

const FiltersForm: React.FC<IFiltersFormProps> = ({ groupTypes, submitForm }) => {
  const { t: translate } = useTranslation();
  const [addressPlaces, setAddressPlaces] = useState(undefined);

  const handleSubmitForm = value => {
    const formObject: IGroupFilters = {
      ...value,
      category: groupTypes.filter(g => g.name === value?.groupType)[0] || undefined,
      location: addressPlaces?.location ? mountAddressFilter(addressPlaces) : undefined,
      distance: addressPlaces?.location ? 5 : 10000,
    };
    submitForm(formObject);
  };

  return (
    <Form onSubmit={({ value }) => handleSubmitForm(value)} style={{ marginTop: 24 }}>
      <div>
        <FormControl label={translate('Endereço')} style={{ marginBottom: 16 }}>
          <PlacesAutocomplete
            name='address'
            id='address'
            placeholder={translate('Encontre por um endereço ou região')}
            type='text'
            icon={<Search size='small' />}
            dropWidth='100%'
            onSelectOption={e => setAddressPlaces(e)}
            onSelectUseMyLocation={e => setAddressPlaces(e)}
            onLoading={() => console.log('')}
            onClear={() => setAddressPlaces(undefined)}
            onError={e => showAlert({ type: 'error', message: translate(e) })}
          />
        </FormControl>
      </div>
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          flexFlow: 'row',
          flexWrap: 'wrap',
        }}>
        <div style={{ width: '48%' }}>
          <FormControl label={translate('Tipo de LINK')} style={{ marginBottom: 16 }}>
            <Select
              name='groupType'
              placeholder={translate('Qualquer tipo')}
              options={[
                { label: translate('Qualquer tipo'), value: '' },
                ...groupTypes.map(type => ({ label: type.name, value: type.name })),
              ]}
            />
          </FormControl>
        </div>
        <div style={{ width: '48%' }}>
          <FormControl label={translate('Dia da Semana')} style={{ marginBottom: 26 }}>
            <Select
              name='day'
              placeholder={translate('Qualquer dia')}
              options={[
                { label: translate('Qualquer dia'), value: '' },
                ...Object.values(WeekdayEnum)
                  .filter(d => (typeof d === 'string' ? Number.isNaN(parseInt(d, 10)) : false))
                  .map(weekday => ({ value: weekday, label: translate(`${weekday}`) })),
              ]}
            />
          </FormControl>
        </div>
      </div>
      <div style={{ display: 'flex', gap: 12 }}>
        <Button
          rounded
          type='submit'
          variant='primary'
          label={translate('Buscar')}
          icon={<Location size='medium' color='#fff' />}
        />
      </div>
    </Form>
  );
};

export default FiltersForm;
