import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Previous } from 'grommet-icons';
import { Button } from '@zion-tech/ds-web';
import useAuthContext from '@shared/auth/Auth.context';
import { getGroups, setGroupSelected } from '@models/Group/Group.state';
import { showAlert } from '@shared/alerts';
import AuthForm from '@shared/auth/AuthForm';
import { AuthContent } from '../Groups.style';

const GroupAuthPage = ({ title }) => {
  const { t: translate } = useTranslation();
  const dispach = useDispatch();
  const navigate = useNavigate();
  const groups = useSelector(getGroups);
  const authContext = useAuthContext();

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    redirectIfNoSelected();
  }, []);

  const redirectIfNoSelected = () => {
    if (!groups?.selected || groups?.selected === null) {
      handleReturnToSearchGroups();
    }
  };

  const handleUserAuthenticated = () => {
    const userFromStorage = authContext.getUserInfo();
    if (groups?.selected && userFromStorage.tipoPessoa !== 'Visitante') {
      navigate(`/links/contact`, { replace: false });
    } else if (groups?.selected && userFromStorage.tipoPessoa === 'Visitante') {
      handleThankYouPage();
    } else {
      handleReturnToSearchGroups();
    }
  };

  const handleThankYouPage = () => {
    dispach(setGroupSelected(undefined));
    navigate(`/links/online-thanks`, { replace: false });
  };

  const handleReturnToSearchGroups = () => {
    dispach(setGroupSelected(undefined));
    navigate(`/links`, { replace: false });
  };

  return groups?.selected ? (
    <AuthContent>
      <Button
        icon={<Previous color='white!' size='small' />}
        label={translate('Voltar para a busca de LINKs')}
        style={{ width: `fit-content`, alignSelf: 'flex-start' }}
        margin={{ bottom: `42px` }}
        onClick={() => handleReturnToSearchGroups()}
      />
      <AuthForm
        showTitle
        showSubtitle
        onSuccess={() => handleUserAuthenticated()}
        onError={event => showAlert(event)}
      />
    </AuthContent>
  ) : null;
};

export default GroupAuthPage;
