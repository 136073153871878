import styled from 'styled-components';
import { Box } from '@zion-tech/ds-web';

export const StyledSplashScreen = styled(Box)`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 80px;
  /* background-image: radial-gradient(circle farthest-corner at 10% 20%, #00b4af 0.1%, #007d74 94.2%); */
`;

export const StyledBox = styled(Box)`
  display: flex;
`;

export const LogoZion = styled.img`
  width: 98px;
`;
