import styled from 'styled-components';
import { TextInput, Box } from '@zion-tech/ds-web';

export const FormContainer = styled(Box)`
  min-height: 500px;
`;

export const PasswordField = styled(Box)`
  height: 36px;
  border-radius: 4px;
  box-shadow: 0px 2px 5px #0000006e;
  border: 1px solid
    ${props =>
      props.error
        ? props.theme.global.colors.feedback3
        : props.theme.global.colors.border[props.theme.dark ? 'dark' : 'light']};

  ${props => props.focused && `border-color: ${props.theme.global.colors.brand}`}
`;

export const PasswordInput = styled(TextInput)`
  height: 30px;
  box-shadow: none !important;
  input {
    box-shadow: none !important;
  }
`;
