import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { CardBody } from '@zion-tech/ds-web';
import { IndexedStep } from './StepProgressBar.style';

export interface Steps {
  currentStep: number;
}

export const StepProgressBar: React.FC<Steps> = ({ currentStep }) => {
  let stepPercentage = 0;

  if (currentStep === 1) {
    stepPercentage = 0;
  } else if (currentStep === 2) {
    stepPercentage = 50;
  } else if (currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <CardBody style={{ width: '100%', fontWeight: 300, marginBottom: 36 }}>
      <ProgressBar percent={stepPercentage} unfilledBackground='#00b4af' filledBackground='#b6e6e4'>
        <Step>
          {({ accomplished }) => (
            <IndexedStep accomplished={accomplished ? 'accomplished' : null} />
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <IndexedStep accomplished={accomplished ? 'accomplished' : null} />
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <IndexedStep accomplished={accomplished ? 'accomplished' : null} />
          )}
        </Step>
      </ProgressBar>
    </CardBody>
  );
};
