import MockAdapter from 'axios-mock-adapter';
import { GroupSearchServiceMock, GroupInfoServiceMock } from '@models/Group/Group.mock';
import { PlanServiceMock, PlansServiceMock } from '@models/Plan/Plan.mock';

const shouldUseMock = process.env.REACT_APP_USE_MOCK;

const registerMocks = axiosInstance => {
  if (shouldUseMock && shouldUseMock === 'true') {
    const adapter = new MockAdapter(axiosInstance);

    /** Groups */
    GroupSearchServiceMock(adapter);
    GroupInfoServiceMock(adapter);

    /** Plans */
    PlansServiceMock(adapter);
    PlanServiceMock(adapter);
  }
};

export default registerMocks;
