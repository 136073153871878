import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from './Alerts.style';

const Alerts = ({ ...props }) => (
  <Container
    position='top-left'
    autoClose={5000}
    draggablePercent={60}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    {...props}
  />
);

export const showAlert = ({ type, message }) => {
  const toasts = {
    info: () => toast.info(message),
    success: () => toast.success(message),
    error: () => toast.error(message),
    warning: () => toast.warn(message),
  };
  return toasts[type]() || toasts.info();
};

export default Alerts;
