import React, { useEffect } from 'react';
import {
  Box,
  Col,
  Row,
  Button,
  Heading,
  Text,
  Form,
  FormControl,
  RadioButtonGroup,
} from '@zion-tech/ds-web';
import { useTranslation } from 'react-i18next';
import { Previous } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGroupSelected } from '@models/Group/Group.state';

const GroupOnlineDetailsPage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleReturnToSearchGroups = () => {
    dispatch(setGroupSelected(undefined));
    navigate(`/links`, { replace: false });
  };

  const submitForm = e => {
    e.preventDefault();
    const { decision } = e.value;
    if (decision === 'sim' || decision === 'talvez') {
      dispatch(setGroupSelected('online'));
      navigate(`/links/auth`, { replace: false });
    }

    if (decision === 'nao') {
      navigate(`/links/online-thanks`, { replace: false });
    }
  };

  return (
    <>
      <Button
        icon={<Previous color='white!' size='small' />}
        label={translate('Voltar para a busca de Links')}
        style={{ width: `fit-content` }}
        onClick={() => handleReturnToSearchGroups()}
      />
      <Box pad={{ horizontal: '5%' }}>
        <Row>
          <Col>
            <Heading level={2} style={{ width: '100%', fontWeight: 600 }} textAlign='center'>
              {translate('Links Online')}
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text size='medium' style={{ lineHeight: '1.8' }}>
              {translate(
                'Ficamos muito felizes em saber que você tem interesse de ser parte de um Link Online!',
              )}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text
              size='large'
              textAlign='center'
              style={{
                width: '100%',
                fontWeight: 600,
                marginBottom: 36,
                marginTop: 36,
                display: 'block',
              }}>
              {translate('Queremos te conhecer melhor')}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text
              size='medium'
              textAlign='center'
              style={{ width: '100%', fontWeight: 600, display: 'block' }}>
              {translate('Você entende que a Zion é ou poderia ser a sua Igreja?')}
            </Text>
            <Form onSubmit={submitForm} style={{ width: '100%' }}>
              <FormControl style={{ marginTop: 36, marginBottom: 36, fontSize: 14 }}>
                <RadioButtonGroup
                  name='decision'
                  options={[
                    { label: translate('Sim').toString(), value: 'sim' },
                    {
                      label: translate('Não, já sou membro de outra igreja.').toString(),
                      value: 'nao',
                    },
                    {
                      label: translate(
                        'Ainda não sei, mas quero conhecer mais a Zion Church.',
                      ).toString(),
                      value: 'talvez',
                    },
                  ]}
                  style={{ width: '100%' }}
                />
              </FormControl>
              <Button
                rounded
                type='submit'
                variant='primary'
                label={translate('Próximo')}
                style={{ width: '48%', justifyContent: 'center' }}
              />
            </Form>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default GroupOnlineDetailsPage;
