import styled from 'styled-components';

export const IndexedStep = styled.div`
  align-items: center;
  background-color: #00b4af;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  width: 20px;

  ${props =>
    props.accomplished &&
    `
    background: #b6e6e4;
    color: white;`}
`;
